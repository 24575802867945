import React, { useEffect, useState } from 'react';
import { NavHeader } from 'Components/Navigation';
import _ from 'lodash';
import { Formik } from 'formik';
import { Alert, PageHeader } from 'Commons';
import { useMediaQuery } from 'Hooks';
import { ImStatsDots } from 'react-icons/im';
import axios from 'axios';
import { adminsColumns } from 'Commons/Table/Columns';
import updateGoogleSheetRegister from 'Utils/GoogleSheet';
import { useNavigate } from 'react-router-dom';
import {
  DATE_FORMAT,
  LOGSHEETS_MANAGERS_SHEET_BASE_URL,
  LOGSHEETS_SITE_MANAGERS_SHEET_ID,
} from 'Constants';
import moment from 'moment';
import TableComponent from 'Commons/Table';
import AdminForm from './Forms/AdminForm';
import { managerValidationSchema } from '../schema';

const initialValues = {
  Name: '',
  Role: '',
  Location: '',
  Position: '',
};
const generateCode = () => {
  const code = _.random(100000, 999999);
  return code;
};

const Admin = () => {
  const [admins, setAdmins] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [refresh, setRefresh] = useState();
  const [loading, setLoading] = useState(false);

  const addManager = async (values) => {
    setLoading(true);
    const AccessDate = moment().format(DATE_FORMAT);

    const data = [
      _.startCase(values.Name),
      `+256${values.PhoneNumber?.substring(1)}`,
      generateCode(),
      AccessDate,
      values.Role,
      values.Location,
      values.Position,
    ];
    try {
      await updateGoogleSheetRegister(
        LOGSHEETS_SITE_MANAGERS_SHEET_ID,
        LOGSHEETS_MANAGERS_SHEET_BASE_URL,
        data,
      );
      Alert('success', 'Manager Added to list');
      setLoading(false);
      setRefresh(Math.random());
    } catch (error) {
      setLoading(false);
      Alert('error', 'Error adding manager to list');
    }
  };

  const getAvailableManagers = async () => {
    try {
      setFetching(true);
      const res = await axios.get(
        `${LOGSHEETS_MANAGERS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_SITE_MANAGERS_SHEET_ID}&returnType=raw`,
        {
          mode: 'no-cors',
        },
      );
      setAdmins(res.data.data);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      Alert('error', 'Error fetching managers');
    }
  };

  useEffect(() => {
    getAvailableManagers();
  }, [refresh]);

  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();

  const actions = [
    {
      name: 'View Realtime Statistics',
      icon: <ImStatsDots />,
      handleClick: () => navigate('/admin/statistics'),
      disabled: false,
    },
  ];

  const adminData = admins.map((admin) => ({
    ...admin,
    Generated_At: moment(admin.Date_Time).format('hh:mm:ss A'),
  }));

  return (
    <>
      <NavHeader isMobile={isMobile} pageTitle='Logsheet Admin Portal' />

      <div className='container-fluid text-primary'>
        <div className='row g-0 align-items-center justify-content-center mb-5'>
          <div className='col-12 col-sm-10 col-md-8'>
            <div className='row g-0 mb-3'>
              <div className='col-12'>
                <div className='row max-800 p-1'>
                  <div className='mt-4 mb-2'>
                    <PageHeader className='sticky-top' title='Add Managers' actions={actions} />

                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={managerValidationSchema}
                      className='needs-validation'
                      onSubmit={async (values, { resetForm }) => {
                        await addManager(values);
                        resetForm();
                      }}
                    >
                      {({ errors, touched, values, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                          <div className='row g-0 align-items-center justify-content-center position-relative'>
                            <>
                              <div className='col-12'>
                                <AdminForm
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  loading={loading}
                                  setFieldValue={setFieldValue}
                                  admins={admins}
                                />
                              </div>
                            </>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className='row g-0 align-items-center justify-content-center pb-4'>
              <PageHeader
                className='sticky-top'
                title={`Manager's List on ${moment(new Date()).format('MMM DD, YYYY')}`}
                actions={[]}
              />
              <div className='col-12 table-responsive'>
                <TableComponent
                  columns={adminsColumns}
                  hideActions
                  data={
                    fetching && admins?.length > 1
                      ? [
                          ...adminData,
                          {
                            id: 1,
                            Name: 'Loading...',
                            Role: 'Loading...',
                            Location: 'Loading...',
                            Position: 'Loading...',
                            Auth_Code: 'Loading...',
                            Generated_At: 'Loading...',
                          },
                        ]
                      : adminData
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
