/* eslint-disable arrow-body-style */
import React from 'react';
import { DATE_FORMAT, ROLES } from 'Constants';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RxCopy } from 'react-icons/rx';
import { MdOutlineCloudDownload } from 'react-icons/md';
import _ from 'lodash';
import moment from 'moment';
import Alert from 'Commons/Alerts';
import QrCode from 'Commons/QRCode';
import { downloadCode } from 'Utils';
// import { Image } from 'react-bootstrap';

export const BookingColumns = [
  {
    Header: 'ID',
    accessor: 'no',
  },
  {
    Header: 'Reference',
    accessor: 'reference',
  },
  {
    Header: 'Booking Date',
    accessor: 'booking_date',
    Cell: ({ cell: { value } }) => moment(value).format(DATE_FORMAT),
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    Cell: ({ cell: { value } }) => moment(value).format(DATE_FORMAT),
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    Cell: ({ cell: { value } }) => moment(value).format(DATE_FORMAT),
  },
  {
    Header: 'Booked By',
    accessor: 'bookedBy',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ cell: { value } }) => (
      <div className={`${value} fw-normal status-label table-label`}>{_.startCase(value)}</div>
    ),
    disableSortBy: true,
    center: true,
  },
];

export const usersColumns = [
  {
    Header: 'No.',
    accessor: 'no',
    disableSortBy: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Telephone',
    accessor: 'telephone',
  },
  {
    Header: 'Permission Level',
    accessor: 'permission_level',
    style: { textAlign: 'center' },
  },
  {
    Header: 'Date Added',
    accessor: 'date_added',
    Cell: ({ cell: { value } }) => moment(value).format(DATE_FORMAT),
    className: 'text-truncate',
    style: { maxWidth: '300px' },
  },
];

export const vehicleColumns = [
  {
    Header: 'License Plate',
    accessor: 'licensePlate',
    style: { textAlign: 'left' },
  },
  {
    Header: 'Qty',
    accessor: 'quantity',
    style: { textAlign: 'left' },
  },
  {
    Header: 'Material',
    accessor: 'material',
    style: { textAlign: 'left' },
  },
  // {
  //   Header: 'Units',
  //   accessor: 'unit',
  //   style: { textAlign: 'left' },
  // },
  {
    Header: 'Driver Name',
    accessor: 'driverName',
    style: { textAlign: 'left', textTransform: 'capitalize' },
  },
  {
    Header: 'Time Added',
    accessor: 'time_added',
    style: { maxWidth: '300px', textAlign: 'left' },
  },
];

export const logsColumns = [
  {
    Header: 'License Plate',
    accessor: 'Vehicle',
    style: { textAlign: 'left' },
  },
  {
    Header: 'Quantity',
    accessor: 'Quantity',
    style: { textAlign: 'left' },
  },
  {
    Header: 'Material',
    accessor: 'Material',
    style: { textAlign: 'left' },
  },
  {
    Header: 'Action',
    accessor: 'Action_Name',
    style: { textAlign: 'left' },
  },
  {
    Header: 'Driver Name',
    accessor: 'Driver_Name',
    style: { textAlign: 'left', textTransform: 'capitalize' },
  },
  {
    Header: 'Time Added',
    accessor: 'time_added',
    style: { maxWidth: '300px', textAlign: 'left' },
  },
];

export const adminsColumns = [
  {
    Header: 'Name',
    accessor: 'Name',
    style: { textAlign: 'left', textTransform: 'capitalize' },
  },
  {
    Header: 'Auth Code',
    accessor: 'Auth_Code',
    Cell: ({ cell: { value } }) => (
      <div
        className='d-flex align-items-center gap-2'
        onClick={async (e) => {
          e.stopPropagation();
        }}
      >
        {value}{' '}
        {value === 'Loading...' ? null : (
          <CopyToClipboard
            text={value}
            onCopy={() => Alert('success', `Copied ${value} clipboard`)}
          >
            <RxCopy className='copy-icon' />
          </CopyToClipboard>
        )}
      </div>
    ),
    style: { textAlign: 'left' },
  },
  {
    Header: 'Role',
    accessor: 'Role',
    Cell: ({ cell: { value } }) => (value === 'Loading...' ? value : `${value} Manager`),
    style: { textAlign: 'left', textTransform: 'capitalize' },
  },
  {
    Header: 'Location',
    accessor: 'Location',
    style: { textAlign: 'left', textTransform: 'capitalize' },
  },
  {
    Header: 'Position',
    accessor: 'Position',
    style: { textAlign: 'left', textTransform: 'capitalize' },
  },

  {
    Header: 'Time Added',
    accessor: 'Generated_At',
    style: { maxWidth: '300px', textAlign: 'left' },
  },
];

export const assignDriversColumns = [
  {
    Header: 'Unique Hash',
    accessor: 'Unique_Hash',
  },
  {
    Header: 'Name Description',
    accessor: 'Name_Description',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Driver Name',
    accessor: 'Driver_Name',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Driver Number',
    accessor: 'Driver_Contact',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Date Time',
    accessor: 'Date_Time',
    Cell: ({ cell: { value } }) =>
      value === 'Loading...' ? 'Loading...' : moment(value).format('DD/MM/YYYY, hh:mm:ss A'),
    style: { maxWidth: '300px', textAlign: 'left', verticalAlign: 'middle' },
  },
];

export const clientsColumns = [
  {
    Header: 'Name',
    accessor: 'Name',
    style: { textAlign: 'left', textTransform: 'capitalize' },
  },
  {
    Header: 'Auth Code',
    accessor: 'Auth_Code',
    Cell: ({ cell: { value } }) => (
      <div
        className='d-flex align-items-center gap-2'
        onClick={async (e) => {
          e.stopPropagation();
        }}
      >
        {value}{' '}
        {value === 'Loading...' ? null : (
          <CopyToClipboard
            text={value}
            onCopy={() => Alert('success', `Copied ${value} clipboard`)}
          >
            <RxCopy className='copy-icon' />
          </CopyToClipboard>
        )}
      </div>
    ),
    style: { textAlign: 'left' },
  },

  {
    Header: 'Address',
    accessor: 'Address',
    style: { textAlign: 'left', textTransform: 'capitalize' },
  },
  {
    Header: 'Phone Number',
    accessor: 'Phone_Number',
    style: { textAlign: 'left', textTransform: 'capitalize' },
  },
  {
    Header: 'Role',
    accessor: 'Role',
    Cell: ({ cell: { value } }) => (value === ROLES.QC_CLIENT.role ? 'Client' : 'Sales Team'),
    style: { textAlign: 'left', textTransform: 'capitalize' },
  },
  {
    Header: 'Time Added',
    accessor: 'Generated_At',
    style: { maxWidth: '300px', textAlign: 'left' },
  },
];

export const packagesColumns = [
  {
    Header: 'QR Code',
    accessor: 'Unique_Hash',
    Cell: ({ cell: { value } }) =>
      value === 'Loading...' ? 'Loading...' : <QrCode url={value} id={`${value}`} size={100} />,
    style: {
      textAlign: 'left',
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
    },
  },

  {
    Header: 'Name Description',
    accessor: 'Name_Description',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Dimension',
    accessor: 'Dimension',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Date Time',
    accessor: 'Date_Time',
    Cell: ({ cell: { value } }) =>
      value === 'Loading...' ? 'Loading...' : moment(value).format('DD/MM/YYYY, hh:mm:ss A'),
    style: { maxWidth: '300px', textAlign: 'left', verticalAlign: 'middle' },
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true,
    Cell: ({
      cell: {
        value,
        row: { original },
      },
    }) => {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            downloadCode(`${original?.Unique_Hash}`);
          }}
          className='d-flex align-items-end gap-2 copy-icon'
        >
          {value !== undefined ? (
            'Loading...'
          ) : (
            <>
              Download <MdOutlineCloudDownload className='fs-2 ' />
            </>
          )}
        </div>
      );
    },
    style: { maxWidth: '300px', textAlign: 'left', verticalAlign: 'middle' },
  },
];
export const salesColumns = [
  {
    Header: 'QR Code',
    accessor: 'Unique_Hash',
    Cell: ({ cell: { value } }) =>
      value === 'Loading...' ? 'Loading...' : <QrCode url={value} id={`${value}`} />,
    style: {
      textAlign: 'left',
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
    },
  },
  {
    Header: 'Destination',
    accessor: 'Destination',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Client Name',
    accessor: 'Client_Name',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Client Phone',
    accessor: 'Client_Phone',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Status',
    accessor: 'Current_Status',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Date Time',
    accessor: 'Date_Time',
    Cell: ({ cell: { value } }) =>
      value === 'Loading...' ? 'Loading...' : moment(value).format('DD/MM/YYYY, hh:mm:ss A'),
    style: { maxWidth: '300px', textAlign: 'left', verticalAlign: 'middle' },
  },
];

export const driverColumns = [
  {
    Header: 'QR Code',
    accessor: 'Unique_Hash',
    Cell: ({ cell: { value } }) =>
      value === 'Loading...' ? 'Loading...' : <QrCode url={value} id={`${value}`} />,
    style: {
      textAlign: 'left',
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
    },
  },
  {
    Header: 'Destination',
    accessor: 'Destination',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Name',
    accessor: 'Name',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Phone',
    accessor: 'Phone',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Status',
    accessor: 'Current_Stage',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Date Time',
    accessor: 'Date_Time',
    Cell: ({ cell: { value } }) =>
      value === 'Loading...' ? 'Loading...' : moment(value).format('DD/MM/YYYY, hh:mm:ss A'),
    style: { maxWidth: '300px', textAlign: 'left', verticalAlign: 'middle' },
  },
];

export const clientColumns = [
  {
    Header: 'QR Code',
    accessor: 'Unique_Hash',
    Cell: ({ cell: { value } }) =>
      value === 'Loading...' ? 'Loading...' : <QrCode url={value} id={`${value}`} />,
    style: {
      textAlign: 'left',
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
    },
  },
  {
    Header: 'Name',
    accessor: 'Name_Description',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Current Status',
    accessor: 'Current_Status',
    Cell: ({ cell: { value } }) => (value === 'Pickup' ? 'In Transit' : value),
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  // {
  //   Header: 'Stage',
  //   accessor: 'Current_Stage',
  //   style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  // },
  {
    Header: 'Date Time',
    accessor: 'Date_Time',
    Cell: ({ cell: { value } }) =>
      value === 'Loading...' ? 'Loading...' : moment(value).format('DD/MM/YYYY, hh:mm:ss A'),
    style: { maxWidth: '300px', textAlign: 'left', verticalAlign: 'middle' },
  },
];

export const statsColumns = [
  {
    Header: 'QR Code',
    accessor: 'Unique_Hash',
    Cell: ({ cell: { value } }) =>
      value === 'Loading...' ? 'Loading...' : <QrCode url={value} id={`${value}`} />,
    style: {
      textAlign: 'left',
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
    },
  },
  {
    Header: 'Name',
    accessor: 'Name_Description',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Sales',
    accessor: 'Sales_Name',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Client',
    accessor: 'Client_Name',
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Current Status',
    accessor: 'Current_Status',
    Cell: ({ cell: { value } }) => (value === 'Pickup' ? 'In Transit' : value),
    style: { textAlign: 'left', textTransform: 'capitalize', verticalAlign: 'middle' },
  },
  {
    Header: 'Date Time',
    accessor: 'Date_Time',
    Cell: ({ cell: { value } }) =>
      value === 'Loading...' ? 'Loading...' : moment(value).format('DD/MM/YYYY, hh:mm:ss A'),
    style: { maxWidth: '300px', textAlign: 'left', verticalAlign: 'middle' },
  },
];
