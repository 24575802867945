/* eslint-disable no-unused-vars */
import { Alert } from 'Commons';
import { FILE_SERVER_URL } from 'Constants';

/**
 * Handle all the API calls to the file server
 * @name fileAdapter
 * @param {(config: RequestConfig)=> any} requestor
 */
const fileUploadsAdapter = () => ({
  /**
   * @name uploadFile - the api token used for upload
   * @param {FormData} data
   * @param {string|null} token
   * @param {(boolean) => {}} setHandlingUpload
   * @param {(Object) => {}} onUploadComplete
   * @param {(Object) => {}} onUploadProgress
   */

  uploadFile: async (data, setHandlingUpload, onUploadComplete, onUploadProgress) => {
    /**
     * @name access_token - the api token used for upload
     *
     */

    const url = `${FILE_SERVER_URL}/upload`;
    const xhr = new XMLHttpRequest();

    xhr.addEventListener(
      'progress',
      (e) => {
        const done = e.position || e.loaded;
        const total = e.totalSize || e.total;
        onUploadProgress(Math.floor(((done / total) * 1000) / 10));
      },
      false,
    );

    if (xhr.upload) {
      xhr.upload.onprogress = (e) => {
        const done = e.position || e.loaded;
        const total = e.totalSize || e.total;
        onUploadProgress(Math.floor(((done / total) * 1000) / 10));
      };
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return;

      if (xhr.status === 200 || xhr.status === 201) {
        if (setHandlingUpload !== undefined) {
          setHandlingUpload(false);
        }

        try {
          const { code, message, file } = JSON.parse(xhr.responseText);

          if (code !== 0) {
            Alert('error', message);
            setHandlingUpload(false);
          } else {
            Alert('success', message);
            onUploadComplete(file);
          }
        } catch (e) {
          if (setHandlingUpload !== undefined) {
            setHandlingUpload(false);
          }
          Alert('error', e.message);
        }
      }

      try {
        const r = JSON.parse(xhr.responseText);
      } catch (e) {
        if (setHandlingUpload !== undefined) {
          setHandlingUpload(false);
        }
        throw new Error(e);
      }
    };

    xhr.open('post', url, true);
    xhr.send(data);
  },
});

export default fileUploadsAdapter;
