import React, { useEffect, useMemo, useState } from 'react';
import { Button, Row, Spinner } from 'react-bootstrap';
import { Input, SelectField } from 'Commons';
import { Field } from 'formik';
import {
  LOGSHEETS_MANAGERS_SHEET_BASE_URL,
  ROLES,
  LOGSHEETS_SITE_MANAGERS_SHEET_ID,
  MANAGER_POSITIONS,
} from 'Constants';
import CreatableSelectField from 'Commons/FormFields/CreatableSelectField';
import axios from 'axios';

export const createOption = (label) => ({
  label,
  value: label,
});

const managerRoles = [
  { value: ROLES.SITE_MANAGER.role, label: 'Site Manager' },
  { value: ROLES.PROJECT_MANAGER.role, label: 'Project Manager' },
];

const managerPositions = [
  { value: MANAGER_POSITIONS.OVERALL, label: 'Overall' },
  { value: MANAGER_POSITIONS.BORROWPIT, label: 'Borrow Pit' },
  { value: MANAGER_POSITIONS.DUMPSITE, label: 'Dump Site' },
];

const AdminForm = ({ errors, values, touched, loading, setFieldValue, admins }) => {
  const [pastAdmins, setPastAdmins] = useState([]);
  const [isFormatted, setIsFormatted] = useState(false);

  useEffect(() => {
    setIsFormatted(false);
    if (values?.licensePlate && /^U[A-Z]{2}\s?[0-9]{3}[A-Z]$/i.test(values?.licensePlate)) {
      if (!isFormatted) {
        const formattedNumber = `${values?.licensePlate
          .substring(0, 3)
          .trim()}${' '}${values?.licensePlate?.substring(3).trim()}`;
        setFieldValue('licensePlate', formattedNumber);
        setIsFormatted(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.licensePlate]);

  const getPastAdmins = async () => {
    try {
      const res = await axios.get(
        `${LOGSHEETS_MANAGERS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_SITE_MANAGERS_SHEET_ID}&returnType=raw&date=past`,
        {
          mode: 'no-cors',
        },
      );
      setPastAdmins(res.data.data);
    } catch (error) {
      // Alert('error', 'Error fetching managers');
    }
  };

  useEffect(() => {
    getPastAdmins();
  }, []);

  const filteredPositions = useMemo(() => {
    if (values?.Role === ROLES.PROJECT_MANAGER.role) {
      return managerPositions.filter((position) => position.value === 'Overall');
    }
    if (values?.Role === ROLES.SITE_MANAGER.role) {
      return managerPositions.filter((position) => position.value !== 'Overall');
    }
    return managerPositions;
  }, [values.Role]);

  useEffect(() => {
    if (values?.Role === ROLES.PROJECT_MANAGER.role) {
      setFieldValue('Position', 'Overall');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.Role]);

  const filteredAdmins = pastAdmins?.filter(
    ({ Name: pAdmin }) => !admins.some(({ Name: cAdmin }) => cAdmin === pAdmin),
  );

  return (
    <Row xs={12} className='g-0 align-items-center justify-content-center'>
      <div className='card my-1'>
        <div className='card-body'>
          <div className='row g-0 align-items-center'>
            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
              <Field
                label='Name'
                as={CreatableSelectField}
                name='Name'
                placeholder='Type to select manager'
                isSearchable
                defaultOptions={filteredAdmins.map((admin) => ({
                  value: admin.Name,
                  label: admin.Name,
                }))}
                createOption={createOption}
                onHandleChange={(value) => {
                  const selectItem = pastAdmins?.find((admin) => admin.Name === value);
                  setFieldValue('Role', selectItem?.Role);
                  setFieldValue('Location', selectItem?.Location);
                  setFieldValue('Position', selectItem?.Position);
                  setFieldValue('PhoneNumber', selectItem?.PhoneNumber);
                  setFieldValue(
                    'PhoneNumber',
                    selectItem?.Phone_Number ? `0${selectItem?.Phone_Number?.substr(4)}` : '',
                  );
                }}
              />
            </div>
            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
              <Input
                type='text'
                name='Location'
                touched={touched}
                errors={errors}
                label='Location'
                customClass='text-capitalize'
              />
            </div>
            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
              <Field
                label='Role'
                as={SelectField}
                name='Role'
                placeholder='Type to select'
                options={managerRoles}
                touched={touched}
                errors={errors}
                defaultValue={managerRoles?.find((v) => v.value === values?.Role)}
              />
            </div>
            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
              <Field
                label='Position'
                as={SelectField}
                name='Position'
                placeholder='Type to select'
                options={filteredPositions}
                touched={touched}
                errors={errors}
                defaultValue={managerPositions.find((v) => v.value === values?.Position)}
              />
            </div>
            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
              <Input
                type='tel'
                name='PhoneNumber'
                touched={touched}
                errors={errors}
                label='Phone Number'
                customClass='text-capitalize'
                placeholder='07xxxxxxxx'
              />
            </div>
          </div>

          <div className='col-12 p-1 bg-white'>
            <div className='row g-0 align-items-center justify-content-end'>
              <div className='col-6 d-flex justify-content-end'>
                <Button
                  id='submit-btn'
                  type='submit'
                  disabled={
                    !values?.Name || !values?.Role || !values?.Location || !values?.PhoneNumber
                  }
                >
                  {loading ? (
                    <Spinner animation='border' size='sm' role='status' variant='minor' />
                  ) : (
                    'Add'
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default AdminForm;
