/* eslint-disable arrow-body-style  */
import axios from 'axios';
import {
  LOGSHEETS_COMBINED_LOGS_SHEET_ID,
  LOGSHEETS_LOGS_SHEET_BASE_URL,
  MANAGER_POSITIONS,
  SYSTEM_REFRESH_RATE,
} from 'Constants';
import React, { useEffect, useState } from 'react';

const RealTimeStatistics = () => {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  const getLogs = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_COMBINED_LOGS_SHEET_ID}&resType=raw`,
        {
          mode: 'no-cors',
        },
      );
      setLogs(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const intervalCall = setInterval(() => {
      getLogs();
    }, SYSTEM_REFRESH_RATE);
    return () => {
      clearInterval(intervalCall);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLogs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredLogs = logs.filter(
    (log) => log.Position === MANAGER_POSITIONS.DUMPSITE && log.Action_Type === 2,
  );

  const units = filteredLogs[0]?.Unit;

  let numberOfCubicMetersDelivered = filteredLogs.reduce((acc, curr) => {
    return acc + curr.Quantity;
  }, 0);

  numberOfCubicMetersDelivered =
    numberOfCubicMetersDelivered > 0 ? numberOfCubicMetersDelivered?.toFixed(2) : 0;

  return (
    <>
      <div
        className='d-flex justify-content-around align-items-center border border-gray rounded-3 p-3'
        style={{
          backgroundColor: 'aliceblue',
        }}
      >
        <div className='text-center'>
          <h4>Number of Trips Delivered</h4>
          <span className='fs-4 text-qc fw-bold'>
            {logs.length < 1 && loading ? 'loading...' : filteredLogs.length}
          </span>
        </div>
        <div className='text-center'>
          <h4>Number of {units ?? 'Cubic Meters'} Delivered</h4>
          <span className='fs-4 text-qc fw-bold'>
            {logs.length < 1 && loading ? 'loading...' : numberOfCubicMetersDelivered}
          </span>
        </div>
      </div>
    </>
  );
};

export default RealTimeStatistics;
