import React from 'react';

const Search = ({ onInputChange }) => (
  <div className='d-flex align-items-center justify-content-end pt-1 pt-md-0 '>
    <div className='col-12 col-md-4'>
      <input
        type='text'
        onChange={onInputChange}
        placeholder='Search'
        className='form-control border border-primary'
        style={{
          height: '100%',
          borderRadius: '1rem 1rem 1rem 1rem',
        }}
      />
    </div>
  </div>
);

export default Search;
