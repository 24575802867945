import { useState, useEffect } from 'react';
import debounce from 'Utils/debounce';

/**
 * @typedef {"xs" | "sm" | "md" | "lg" | "xl" | "xxl"} Breakpoint
 */

export const SIZE_XS = 'xs';
export const SIZE_SM = 'sm';
export const SIZE_MD = 'md';
export const SIZE_LG = 'lg';
export const SIZE_XL = 'xl';
export const SIZE_XXL = 'xxl';

export const MOBILE_VIEWS = [SIZE_XS, SIZE_SM];
export const DESKTOP_VIEWS = [SIZE_MD, SIZE_LG, SIZE_XL, SIZE_XXL];

/**
 * @param {number} width
 * @returns {Breakpoint}
 */
const resolveBreakpoint = (width) => {
  if (width < 576) {
    return SIZE_XS;
  }
  if (width >= 576 && width < 768) {
    return SIZE_SM;
  }
  if (width >= 768 && width < 992) {
    return SIZE_MD;
  }
  if (width >= 992 && width < 1200) {
    return SIZE_LG;
  }
  if (width >= 1200 && width < 1440) {
    return SIZE_XL;
  }
  if (width >= 1440) {
    return SIZE_XXL;
  }
  return SIZE_XS;
};

/**
 * @name useMediaQuery
 * @returns {Breakpoint}
 */
const useMediaQuery = () => {
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = debounce(() => {
      setSize(resolveBreakpoint(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return { size, isMobile: MOBILE_VIEWS.includes(size) };
};

export default useMediaQuery;
