import React from 'react';

/**
 *
 * @param {{title: string, type: "alert" | "prompt", input : {label:string}, primary: string, minor: string, cancel_btn_msg: string,submit_btn_msg:string, handleSubmit:Function, handleCancel: Function }} props
 * @returns
 */
const Dialog = ({
  id,
  title,
  type,
  input,
  primary,
  minor,
  cancel_btn_msg,
  submit_btn_msg,
  handleSubmit,
  handleCancel,
  Component,
  disabled,
  modalSize,
}) => {
  const { label } = input || {};

  return (
    <>
      <div className='modal modal-width-mobile-styles' id={id} tabIndex='-1'>
        <div
          className={`modal-dialog modal-dialog-centered modal-dialog-scrollable modal-width-mobile ${
            modalSize ?? ''
          }`}
        >
          <div className='modal-content modal-shadow'>
            {title && (
              <div className='modal-header'>
                <h5 className='modal-title text-primary' id='dialog-title'>
                  {title}
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                />
              </div>
            )}

            <div className='modal-body'>
              <div className='row g-0'>
                {Component && Component}
                {primary && (
                  <div id='dialog-primary-msg' className='col-12 text-start text-primary fw-light'>
                    {primary}
                  </div>
                )}
                {minor && (
                  <div
                    id='dialog-minor-msg'
                    className='col-12 text-start text-primary fw-bold mt-1'
                  >
                    {minor}
                  </div>
                )}

                {type === 'prompt' && input && (
                  <div className='col-12'>
                    <label htmlFor='dialog-input' className='form-label'>
                      {label}
                    </label>
                    <textarea className='form-control' id='dialog-input' rows='3' />
                  </div>
                )}
              </div>
            </div>
            {submit_btn_msg && (
              <div className='modal-footer'>
                {type === 'prompt' && (
                  <button
                    type='button'
                    id='dialog-cancel-btn'
                    className='btn btn-minor text-primary'
                    onClick={handleCancel}
                    disabled={disabled}
                  >
                    {cancel_btn_msg}
                  </button>
                )}

                <button
                  type='button'
                  id='dialog-submit-btn'
                  className='btn btn-primary'
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  {submit_btn_msg}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dialog;
