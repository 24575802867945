import React from 'react';
import Table from 'Commons/Table';
import { vehicleColumns } from 'Commons/Table/Columns';

const DesktopView = ({ vehicles, isLoading }) => (
  <div className='row g-0 align-items-center justify-content-center'>
    <div className='col-12 table-responsive'>
      <Table
        columns={vehicleColumns}
        hideActions
        data={
          isLoading && vehicles?.length < 1
            ? [
                ...vehicles,
                {
                  id: 1,
                  driverName: 'Loading...',
                  licensePlate: 'Loading...',
                  quantity: 'Loading...',
                  unit: 'Loading...',
                  material: 'Loading...',
                  time_added: 'Loading...',
                },
              ]
            : vehicles
        }
      />
    </div>
  </div>
);

export default DesktopView;
