const isLocalhost = process.env.NODE_ENV === 'development';

export const SYSTEM_REFRESH_RATE = 5000;
export const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const FILE_SERVER_URL = 'https://store.pearlgroupdev.com';

// SHEET IDs
export const LOGSHEETS_SITE_MANAGERS_SHEET_ID = isLocalhost ? '757252715' : '1677513832';
export const LOGSHEETS_VEHICLES_SHEET_ID = isLocalhost ? '2120308958' : '1260667440';
export const LOGSHEETS_COMBINED_LOGS_SHEET_ID = isLocalhost ? '2111229639' : '1737896139';
export const LOGSHEETS_DUMPSITE_LOGS_SHEET_ID = isLocalhost ? '1038536169' : '754131840';
export const LOGSHEETS_BORROWPIT_LOGS_SHEET_ID = isLocalhost ? '843335139' : '317632858';
export const LOGSHEETS_OPERATIONS_LOGS_SHEET_ID = isLocalhost ? '1321481134' : '924248048';
export const LOGSHEETS_EOD_LOGS_SHEET_ID = isLocalhost ? '1614016547' : '802077132';

// SHEET URLs
export const LOGSHEETS_LOGS_SHEET_BASE_URL = isLocalhost
  ? 'https://script.google.com/macros/s/AKfycbwDKFHTaoMxN3v5JWUnpU8eSGFFaxn1t7LCfY5q4f9eA0sq8OuXsac4jddx1cFDtIi69w/exec' // Dev_Logs_V1.1.2
  : 'https://script.google.com/macros/s/AKfycbwEAaRoXMmjBw1NbUg13AwXLgg6wlUCO85JUK7PfUm9vG5ofKXipEUJR_IoSPzJR8Ix/exec'; // Logs_V1.0.5

export const LOGSHEETS_MANAGERS_SHEET_BASE_URL = isLocalhost
  ? 'https://script.google.com/macros/s/AKfycbwMnUgxbxA1AoipxHTzBZbYrH9-pqh9EUujoYMgGMGa62xCiZIxZDO7nXEISKWDLKn0/exec' // Dev_Auth_V1.1.0
  : 'https://script.google.com/macros/s/AKfycbyqzhC2OEDmul9SZNl5sqfzv5OK9qBHE5eCZ5VUG2sNimXMP2BerHFtNp5L_8kI5ypB/exec'; // Auth_V1.0.4

export const ROLES = {
  PROJECT_MANAGER: { role: 'project', name: 'Pearl Rides Project Manager' },
  SITE_MANAGER: { role: 'site', name: 'Pearl Rides Site Manager' },
  ADMIN: { role: 'admin', name: 'Pearl Rides Admin' },
};

export const MANAGER_POSITIONS = {
  DUMPSITE: 'Dump Site',
  BORROWPIT: 'Borrow Pit',
  OVERALL: 'Overall',
};

export const PACKAGE_STATUS = {
  READY: 'Ready',
  DELIVERED: 'Delivered',
  IN_TRANSIT: 'In Transit',
  PICKED_UP: 'Picked Up',
};

export const MATERIALS = {
  Aggregate: { name: 'Aggregate', unit: 'Tons' },
  Murram: { name: 'Murram', unit: 'Cubic Metres' },
  'Hard Core': { name: 'Hard Core', unit: 'Tons' },
};

export const materialOptions = [
  { value: 'Murram', label: 'Murram' },
  { value: 'Aggregate', label: 'Aggregate' },
  { value: 'Hard Core', label: 'Hard Core' },
];

export const actionTypes_BorrowPit = [
  { value: 1, label: 'Arrive at Site' },
  { value: 2, label: 'Start Loading' },
  { value: 3, label: 'Depart Site' },
];
export const actionTypes_Dumpsite = [
  { value: 1, label: 'Arrive at Site' },
  { value: 2, label: 'Start Off-Loading' },
  { value: 3, label: 'Depart Site' },
];

export const vehicleOperationActionsTypes = [
  { value: 1, label: 'Vehicle Fuelled' },
  { value: 2, label: 'Vehicle Break Down' },
  { value: 3, label: 'Vehicle Stopped' },
  { value: 4, label: 'Vehicle Restarted' },
  { value: 5, label: 'Change Material' },
];
