import { Alert } from 'Commons';
import fileUploadsAdapter from './fileUpload';
import emailAdapter from './email';
import logsAdapters from './logs';

export const fileRequestor = async (config) => {
  try {
    let uploadData;
    const { url, data, headers } = config;
    if (data) {
      if (headers['Content-Type'].includes('form-data')) {
        uploadData = data;
      } else {
        uploadData = JSON.stringify(data);
      }
    } else uploadData = null;

    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        ...headers,
        Accept: 'application/json',
      },
      body: uploadData,
      ...config,
    });

    const contentType = res.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      throw new TypeError('Invalid response type returned');
    }

    const response = await res.json();
    const { code, message } = response || {};

    if (code !== 0) {
      Alert('error', message);
      throw new Error(message);
    } else {
      return response;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const FILE_SERVER_APIS = fileUploadsAdapter(fileRequestor);
export const EMAIL_APIS = emailAdapter();
export const LOGS_APIS = logsAdapters();
