import {
  LOGSHEETS_COMBINED_LOGS_SHEET_ID,
  LOGSHEETS_LOGS_SHEET_BASE_URL,
  LOGSHEETS_VEHICLES_SHEET_ID,
} from 'Constants';
import axios from 'axios';

/**
 * Handle all the API calls to handle send email notifications
 * @name logsAdapter
 * @param {(config: RequestConfig)=> any} requestor
 */
const logsAdapter = () => ({
  /**
   * Get the logs for the current day
   * @name getTodaysLogs
   * @returns {Promise<LogsResponse>}
   */
  getTodaysLogs: async (
    baseUrl = LOGSHEETS_LOGS_SHEET_BASE_URL,
    searchQueries = `sheetId=${LOGSHEETS_COMBINED_LOGS_SHEET_ID}&date=today`,
  ) => {
    const response = await axios.get(`${baseUrl}?${searchQueries}`, {
      mode: 'no-cors',
    });
    if (response.data.data.length > 0) {
      const resData = response.data.data;
      return resData;
    }
    return [];
  },

  /**
   * Get all the vehicles available for work today
   * @name getAvailableVehicles
   * @returns {Promise<LogsResponse>}
   */
  getAvailableVehicles: async (
    baseUrl = LOGSHEETS_LOGS_SHEET_BASE_URL,
    searchQueries = `sheetId=${LOGSHEETS_VEHICLES_SHEET_ID}&date=today`,
  ) => {
    const response = await axios.get(`${baseUrl}?${searchQueries}`, {
      mode: 'no-cors',
    });
    if (response.data.data.length > 0) {
      const resData = response.data.data;
      return resData;
    }
    return [];
  },
});

export default logsAdapter;
