import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';

const validationSchema = Yup.object().shape({
  address_line_1: Yup.string().required('Build / Establishment Name is required.'),
  address_line_2: Yup.string().required('The Street Address is required.'),
  city: Yup.string().required('The city or town name is required.'),
  country: Yup.string().required('The Country is required.'),
});

const AddressForm = ({ selectedAddress, hideModal, updateAddress }) => {
  const [address, setAddress] = useState(selectedAddress);

  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});

  const onInputChange = (e) => {
    setAddress((d) => ({ ...d, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(address, { abortEarly: false });
      setErrors({});
      hideModal();
      updateAddress(address);
      // setAddress(() => ({
      //   address_line_1: '',
      //   address_line_2: '',
      //   city: '',
      //   state: '',
      //   country: '',
      //   postcode: '',
      //   pobox: '',
      // }));
    } catch (error) {
      const { inner } = error || {};

      const validationErrors = inner.reduce(
        (errs, currentValidation) =>
          Object.assign(errs, {
            [currentValidation.path]: currentValidation.errors[0],
          }),
        {},
      );
      setErrors(validationErrors);
      setValidated(false);
    }
  };

  const {
    address_line_1 = '',
    address_line_2 = '',
    city = '',
    state = '',
    country = '',
    postcode = '',
    pobox = '',
  } = address || {};

  const hasErrors = (key) => key in errors;

  return (
    <div className='container-fluid text-primary'>
      <div className='row g-0 align-items-center justify-content-center'>
        <div className='col'>
          <Form noValidate validated={validated} onSubmit={handleSubmit} id='address_details'>
            <div className='col-12 m-2'>
              <Form.Group controlId='address_line_1'>
                <Form.Label>Building / Establishment Name</Form.Label>
                <Form.Control
                  type='text'
                  name='address_line_1'
                  onChange={onInputChange}
                  value={address_line_1}
                  placeholder='e.g., Boulevard Complex'
                  isInvalid={hasErrors(address_line_1)}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.address_line_1}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className='col-12 m-2'>
              <Form.Group controlId='address_line_2'>
                <Form.Label>Street Address</Form.Label>
                <Form.Control
                  type='text'
                  name='address_line_2'
                  onChange={onInputChange}
                  value={address_line_2}
                  placeholder='e.g., Plot 22 Upper Kololo Terrace'
                  isInvalid={hasErrors(address_line_2)}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.address_line_2}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className='col-12 m-2'>
              <Form.Group controlId='city'>
                <Form.Label>City / Town</Form.Label>
                <Form.Control
                  type='text'
                  name='city'
                  onChange={onInputChange}
                  value={city}
                  placeholder='e.g., Kampala'
                  isInvalid={hasErrors(city)}
                />
                <Form.Control.Feedback type='invalid'>{errors.city}</Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className='col-12 m-2'>
              <Form.Group controlId='state'>
                <Form.Label>State / Region</Form.Label>
                <Form.Control
                  type='text'
                  name='state'
                  onChange={onInputChange}
                  value={state}
                  placeholder='e.g., Central'
                  isInvalid={hasErrors(state)}
                />
                <Form.Control.Feedback type='invalid'>{errors.state}</Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className='col-12 m-2'>
              <Form.Group controlId='country'>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type='text'
                  name='country'
                  onChange={onInputChange}
                  value={country}
                  placeholder='e.g., Uganda'
                  isInvalid={hasErrors(country)}
                />
                <Form.Control.Feedback type='invalid'>{errors.country}</Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className='col-12 m-2'>
              <Form.Group controlId='postcode'>
                <Form.Label>Postal / ZIP Code</Form.Label>
                <Form.Control
                  type='text'
                  name='postcode'
                  onChange={onInputChange}
                  value={postcode}
                  placeholder='e.g., 000000'
                  isInvalid={hasErrors(postcode)}
                />
                <Form.Control.Feedback type='invalid'>{errors.postcode}</Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className='col-12 m-2'>
              <Form.Group controlId='pobox'>
                <Form.Label>P.O Box Number</Form.Label>
                <Form.Control
                  type='text'
                  name='pobox'
                  onChange={onInputChange}
                  value={pobox}
                  placeholder='e.g., P.O Box 0000'
                  isInvalid={hasErrors(pobox)}
                />
                <Form.Control.Feedback type='invalid'>{errors.pobox}</Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className='col-12 mt-4'>
              <div className='row g-0 align-items-center justify-content-between'>
                <div className='col-6'>
                  <button type='button' className='btn btn-outline-primary' onClick={hideModal}>
                    Cancel
                  </button>
                </div>

                <div className='col-6 d-flex align-items-start justify-content-end'>
                  <button
                    className='btn btn-primary'
                    type='button'
                    id='address-submit-btn'
                    onClick={handleSubmit}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

const AddressModal = ({ id, selectedAddress, onHide, onAddressUpdate }) => (
  <>
    <div tabIndex='-1' id={id} className='modal'>
      <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Address Details</h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            />
          </div>
          <div className='modal-body'>
            {selectedAddress && 'address_line_1' in selectedAddress && (
              <AddressForm
                hideModal={onHide}
                updateAddress={onAddressUpdate}
                selectedAddress={selectedAddress}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </>
);

export default AddressModal;
