import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { useCookies } from 'react-cookie';
import { logout } from 'Store/slices/authSlice';

const NavHeader = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [, , removeCookie] = useCookies(['user']);
  const { isMobile, pageTitle } = props;
  const { pathname } = window.location;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logo_site = `${process.env.PUBLIC_URL}/assets/logos/logo.png`;
  const logo_project = `${process.env.PUBLIC_URL}/assets/logos/DarkBlue.svg`;

  const handleLogout = () => {
    removeCookie('user', { path: '/' });
    dispatch(logout());
    if (window.location.pathname.includes('quality-chemicals')) {
      navigate('/quality-chemicals');
    } else {
      navigate('/');
    }
  };

  let headerStyles = 'bg-primary text-minor';
  let logo = logo_project;
  if (pathname === '/site-manager') {
    logo = logo_site;
    headerStyles = 'bg-minor text-white';
  }

  return (
    <div
      className={`row g-0 align-items-center justify-content-center sticky-top ${headerStyles}`}
      style={{ zIndex: 300 }}
    >
      <div className='col-12 my-2'>
        <div className='col-12'>
          <div
            className='align-items-center d-flex'
            style={{
              maxWidth: '100%',
              margin: '0 auto',
              justifyContent: 'space-between',
              paddingLeft: '10px',
            }}
          >
            <div
              className={`d-flex justify-content-end ${
                isMobile ? 'col-2 pointer' : 'col-1 pointer'
              }`}
              onClick={() => navigate('/')}
            >
              <img
                src={logo}
                className='rounded img-fluid mx-1'
                alt='Pearl Rides: Travel Seamlessly'
                style={{ maxHeight: '3.5rem' }}
              />
            </div>

            <div
              className='col-8'
              style={{
                paddingLeft: '6px',
              }}
            >
              <div className='d-flex align-items-center'>
                <div>
                  <h4 className='mb-0'>{pageTitle}</h4>
                  <p className='mb-0 text-sm ml-4'>
                    {user?.Name} at {user?.Location ?? user.Address}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-2 d-flex justify-content-end' style={{ padding: '0px 10px' }}>
              <FiLogOut
                size='2rem'
                className='text-lg'
                style={{ cursor: 'pointer' }}
                onClick={handleLogout}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
