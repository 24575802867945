import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import store from 'Store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'bootstrap-icons/font/bootstrap-icons.css';
import './Styles/index.scss';

const AppContainer = document.getElementById('root');
const root = createRoot(AppContainer);

root.render(
  <Provider store={store}>
    <CookiesProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </CookiesProvider>
  </Provider>,
);

serviceWorkerRegistration.register();
reportWebVitals();
