/* eslint-disable arrow-body-style  */
import { LOGS_APIS } from 'Adapters';
import { MANAGER_POSITIONS } from 'Constants';
import _ from 'lodash';
import moment from 'moment';

export const isEmptyObject = (obj) => JSON.stringify(obj) === '{}';

const randomStringGenerator = (length, charracters) => {
  const randomArray = Array.from(
    { length },
    () => charracters[Math.floor(Math.random() * charracters.length)],
  );
  const randomPassword = randomArray.join('');
  return randomPassword;
};

export const generateRandomString = ({ length, firstChar }) => {
  const chars =
    "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890`!@#$%^&*()_+-={};':\\|,.<>/?~";

  const regexForLowercase = /[a-z]/g;
  const regexForUppercase = /[A-Z]/g;
  const regexForNumbers = /[0-9]/g;
  const regexForSymbols = /[^a-zA-Z0-9 ]/g;

  const withNumbers = chars.match(regexForNumbers);
  const withSymbols = chars.match(regexForSymbols);
  const withUppercaseLetters = chars.match(regexForUppercase);
  const withLowercaseLetters = chars.match(regexForLowercase);

  let newCharSet = '';
  const mapTOflags = {
    hasUpperCase: withUppercaseLetters.join(''),
    hasLowerCase: withLowercaseLetters.join(''),
    hasNumbers: withNumbers.join(''),
    hasSymbols: withSymbols.join(''),
  };

  const criteria = {
    hasUpperCase: true,
    hasSymbols: false,
    hasNumbers: true,
    hasLowerCase: true,
  };

  Object.keys(criteria).forEach((key) => {
    if (criteria[key] && mapTOflags[key]) {
      newCharSet += mapTOflags[key];
      return newCharSet;
    }
    return newCharSet;
  });
  const generatedString = randomStringGenerator(length, newCharSet);

  if (firstChar) {
    return `${firstChar}${generatedString}`;
  }
  return generatedString;
};

export const downloadCode = (item) => {
  const canvas = document.getElementById(item);
  if (canvas) {
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${item}-QrCode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};

export const generateAuthCode = () => {
  const code = _.random(100000, 999999);
  return code;
};

/**
 * @name generatedEodReport
 * @description generates end of day report
 * @param {string} project_name
 * @param {string} client_name
 * @returns {object} report
 */
export const generatedEodReport = async (
  project_name = 'King Albert Road',
  client_name = 'King Albert',
) => {
  const logs = await LOGS_APIS.getTodaysLogs();
  const vehicles = {};

  logs.forEach((log) => {
    const { Vehicle } = log;
    const reg_no = Vehicle.replace(' ', '_');
    if (vehicles[reg_no]) {
      vehicles[reg_no].push(log);
    } else {
      vehicles[reg_no] = [log];
    }
  });

  const filteredLogs = logs.filter(
    (log) => log.Position === MANAGER_POSITIONS.DUMPSITE && log.Action_Type === 2,
  );

  const numberOfCubicMetersDelivered = filteredLogs.reduce((acc, curr) => {
    return acc + curr.Quantity;
  }, 0);

  const availableTrucks = await LOGS_APIS.getAvailableVehicles();

  const f = logs
    .filter((a) =>
      availableTrucks.some(
        (b) => a.Vehicle === b.Reg_No && a.Action_Type === 2 && a.Position === 'Dump Site',
      ),
    )
    .reduce((acc, cur) => Object.assign(acc, { [cur.Vehicle]: cur }), {});

  const no_trucks = Object.keys(f).length;

  const vehicle_summaries = [];
  const vehicle_details = [];

  Object.keys(vehicles).forEach((key) => {
    const vehicle = vehicles[key];
    const dumpsiteLogs = vehicle.filter(
      (log) => log.Position === MANAGER_POSITIONS.DUMPSITE && log.Action_Type === 2,
    );
    const reg_no = key.replace('_', ' ');
    const unit = vehicle[0].Unit;
    const no_units = vehicle[0].Quantity;
    const no_trips = dumpsiteLogs.length;
    const total_units = dumpsiteLogs.reduce((acc, curr) => {
      return acc + curr.Quantity;
    }, 0);

    vehicle_summaries.push({
      reg_no,
      unit,
      no_units,
      no_trips,
      total_units,
    });

    vehicle_details.push({
      reg_no,
      driver: vehicle[0].Driver_Name,
      trips: vehicle
        .filter((v) => v?.Position === MANAGER_POSITIONS.DUMPSITE && v?.Action_Type === 2)
        .map((trip, idx) => {
          return {
            trip_no: idx + 1,
            time: moment(trip.Date_Time).format('hh:mm:ss A'),
            material: trip.Material,
          };
        }),
    });
  });

  return {
    project_name: _.upperCase(project_name),
    delivery_date: moment().format('MMMM Do, YYYY'),
    starting_time: moment(logs[0].Date_Time).format('hh:mm:ss A'),
    closing_time: moment(logs[logs.length - 1].Date_Time).format('hh:mm:ss A'),
    no_trucks,
    no_trips: filteredLogs.length,
    unit: filteredLogs[0].Unit,
    no_units: numberOfCubicMetersDelivered,
    dumpsite_manager: logs.find((log) => log.Position === MANAGER_POSITIONS.DUMPSITE).Action_By,
    borrowpit_manager: logs.find((log) => log.Position === MANAGER_POSITIONS.BORROWPIT).Action_By,
    project_manager: availableTrucks[0].Action_By,
    vehicle_summaries,
    vehicle_details,
    client_name,
  };
};
