import * as Yup from 'yup';

export const vehicleValidationSchema = Yup.object().shape({
  regNo: Yup.object().required('License Plate is required'),
  volume: Yup.number('Volume must be a number').required('Volume Capacity is required'),
  weight: Yup.number('Weight must be a number').required('Weight Capacity is required'),
  driverName: Yup.string().required('Driver Name is required'),
  material: Yup.string().required('Material is required'),
  driverNumber: Yup.string()
    .required("Driver's Number is required")
    .test('len', 'Must be exactly 10 characters', (val) => val?.toString().length === 10),
});

export const managerValidationSchema = Yup.object().shape({
  Name: Yup.string().required('Name is required'),
  Role: Yup.string().required('Role is required'),
  Location: Yup.string().required('Manager location is required'),
  Position: Yup.string().required("Manager's position is required"),
  PhoneNumber: Yup.string()
    .required('Phone Number is required')
    .test('len', 'Must be exactly 10 characters', (val) => val?.toString().length === 10),
});

export const assignValidationSchema = Yup.object().shape({
  package: Yup.object().required('Package is required'),
  driver: Yup.object().required('Driver name is required'),
});

export const clientsValidationSchema = Yup.object().shape({
  Name: Yup.string().required('Name is required'),
  clientType: Yup.string().required('Type is required'),
  Address: Yup.string().required('Address is required'),
  PhoneNumber: Yup.string()
    .required('Phone Number is required')
    .test('len', 'Must be exactly 10 characters', (val) => val?.toString().length === 10),
  Email: Yup.string().email('Email is invalid').required('Email is required'),
});

export const salesValidationSchema = Yup.object().shape({
  Name: Yup.string().required('Name is required'),
  Address: Yup.string().required('Branch is required'),
  Email: Yup.string().email('Email is invalid').required('Email is required'),
  PhoneNumber: Yup.string()
    .required('Phone Number is required')
    .test('len', 'Must be exactly 10 characters', (val) => val?.toString().length === 10),
});

export const vehicleActionValidationSchema = Yup.object().shape({
  regNo: Yup.object().required('License Plate is required'),
  actionType: Yup.string().required('Action Type is required'),
  fuel_Type: Yup.string().when('actionType', {
    is: '1',
    then: Yup.string().required('Fuel Type is required'),
  }),
  fuel_litres: Yup.number().when('actionType', {
    is: '1',
    then: Yup.number().required('Volume of fuel taken is required'),
  }),
  unit_cost: Yup.number().when('actionType', {
    is: '1',
    then: Yup.number().required('Unit cost of fuel is required'),
  }),
});

export const logActionValidationSchema = Yup.object().shape({
  regNo: Yup.object().required('License Plate is required'),
  actionType: Yup.string().required('Action Type is required'),
});

export const eodValidationSchema = Yup.object().shape({
  numberOfTrips: Yup.number().required('Number of Trips is required'),
  unitCost_Diesel: Yup.string().when('fuel_Diesel', {
    is: (fuel_Diesel) => fuel_Diesel > 0,
    then: Yup.string().required('Diesel Unit Cost is required'),
  }),
  unitCost_Petrol: Yup.string().when('fuel_Petrol', {
    is: (fuel_Petrol) => fuel_Petrol > 0,
    then: Yup.string().required('Petrol Unit Cost is required'),
  }),
});
