/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  isLoggedIn: false,
};

export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    autoAuthenticationSuccess(state, { payload }) {
      state.user = payload;
      state.isLoggedIn = !!state.user;
    },
    authenticationSuccess(state, { payload }) {
      state.user = payload;
      state.isLoggedIn = !!state.user;
    },
    logout(state) {
      state.user = {};
      state.isLoggedIn = false;
    },
  },
});
const { reducer, actions } = authSlice;

export const { autoAuthenticationSuccess, authenticationSuccess, logout } = actions;

export default reducer;
