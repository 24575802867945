import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useField } from 'formik';

const SelectField = (props) => {
  const [selectedFieldValue, setSelectedFieldValue] = useState();
  const { name, label, isMulti, defaultValue, ...rest } = props;
  const [, state, { setValue, setTouched }] = useField(name);

  const onChange = (option) => {
    setSelectedFieldValue(option);
    if (isMulti) {
      setValue(option.map((el) => el.value));
    } else {
      setValue(option?.value);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedFieldValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className='col-12'>
      {label ? (
        <label htmlFor={name} className='form-label fw-bold'>
          {label}
        </label>
      ) : null}
      <Select
        {...rest}
        onChange={onChange}
        defaultValue={defaultValue}
        value={selectedFieldValue}
        onBlur={setTouched}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: state.touched && state.error ? 'red' : '#ced4da',
          }),
        }}
      />
      {state.touched && state.error && (
        <div style={{ color: '#d32f2f', fontSize: '12px', paddingLeft: 3, paddingTop: 3 }}>
          {state.error}
        </div>
      )}
    </div>
  );
};

export default SelectField;
