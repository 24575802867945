import React, { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { Input, SelectField } from 'Commons';
import LicensePlateField from 'Commons/FormFields/LicensePlateField';
import { Field } from 'formik';
import {
  LOGSHEETS_LOGS_SHEET_BASE_URL,
  LOGSHEETS_VEHICLES_SHEET_ID,
  materialOptions,
} from 'Constants';
import axios from 'axios';

const Vehicle = ({ errors, values, touched, loading, setFieldValue, vehicles }) => {
  const [isFormatted, setIsFormatted] = useState(false);
  const [previousVehicles, setPreviousVehicles] = useState([]);

  useEffect(() => {
    setIsFormatted(false);
    if (values?.licensePlate && /^U[A-Z]{2}\s?[0-9]{3}[A-Z]$/i.test(values?.licensePlate)) {
      if (!isFormatted) {
        const formattedNumber = `${values?.licensePlate
          .substring(0, 3)
          .trim()}${' '}${values?.licensePlate?.substring(3).trim()}`;
        setFieldValue('licensePlate', formattedNumber);
        setIsFormatted(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.licensePlate]);

  const getPreviousVehicles = async () => {
    try {
      const res = await axios.get(
        `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_VEHICLES_SHEET_ID}&date=past`,
        {
          mode: 'no-cors',
        },
      );
      setPreviousVehicles(res.data.data);
    } catch (error) {
      // Alert('error', 'Error fetching managers');
    }
  };

  useEffect(() => {
    getPreviousVehicles();
  }, []);

  const filteredVehicles = previousVehicles?.filter(
    ({ Reg_No: pReg_No }) => !vehicles?.some(({ Reg_No: cReg_No }) => cReg_No === pReg_No),
  );

  return (
    <Row xs={12} className='g-0 align-items-center justify-content-center'>
      <div className='card my-1'>
        <div className='card-body'>
          <div className='row g-0 align-items-center'>
            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
              <Field
                label='License Plate'
                as={LicensePlateField}
                name='regNo'
                placeholder='Type to select'
                isSearchable
                defaultOptions={filteredVehicles.map((item) => ({
                  value: item,
                  label: item.Reg_No,
                }))}
                noModal
                onHandleChange={(value) => {
                  const selectItem = filteredVehicles?.find((v) => v.Reg_No === value?.Reg_No);
                  setFieldValue('volume', selectItem?.Volume_Capacity);
                  setFieldValue('weight', selectItem?.Weight_Capacity);
                  setFieldValue(
                    'driverNumber',
                    selectItem?.Driver_Number ? `0${selectItem?.Driver_Number.substr(4)}` : '',
                  );
                  setFieldValue('driverName', selectItem?.Driver_Name);
                  setFieldValue('material', selectItem?.Material);
                }}
              />
            </div>

            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
              <Input
                type='number'
                name='volume'
                touched={touched}
                errors={errors}
                label='Volume Capacity'
                isVolume
                step='0.01'
                min={0.0}
              />
            </div>
            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
              <Input
                type='number'
                name='weight'
                touched={touched}
                errors={errors}
                label='Weight Capacity (Tons)'
                step='0.01'
                min={0.0}
              />
            </div>
            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
              <Field
                label='Material'
                as={SelectField}
                name='material'
                placeholder='Type to select'
                options={materialOptions}
                touched={touched}
                errors={errors}
                defaultValue={materialOptions?.find((v) => v.value === values?.material)}
              />
            </div>
            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
              <Input
                type='text'
                name='driverName'
                touched={touched}
                errors={errors}
                label='Driver Name'
                customClass='text-capitalize'
              />
            </div>
            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
              <Input
                type='tel'
                name='driverNumber'
                touched={touched}
                errors={errors}
                label="Driver's Number"
                customClass='text-capitalize'
                placeholder='07xxxxxxxx'
              />
            </div>
          </div>

          <div className='col-12 p-1 bg-white'>
            <div className='row g-0 align-items-center justify-content-end'>
              <div className='col-6 d-flex justify-content-end'>
                <Button
                  id='submit-btn'
                  type='submit'
                  disabled={
                    !values?.regNo ||
                    !values?.volume ||
                    !values?.weight ||
                    !values?.material ||
                    !values?.driverName ||
                    !values?.driverNumber
                  }
                >
                  <div className='col'>{loading ? 'Adding...' : 'Add Vehicle'}</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default Vehicle;
