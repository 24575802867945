// import axios from 'axios';
// import { PageHeader } from 'Commons';
// import TableComponent from 'Commons/Table';
// import { logsColumns } from 'Commons/Table/Columns';
import { NavHeader } from 'Components/Navigation';
// import {
//   SYSTEM_REFRESH_RATE,
//   LOGSHEETS_COMBINED_LOGS_SHEET_ID,
//   LOGSHEETS_LOGS_SHEET_BASE_URL,
// } from 'Constants';
import { FaArrowLeft } from 'react-icons/fa';
import { useMediaQuery } from 'Hooks';
// import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogsComponent from './LogsComponent';

const Logs = () => {
  const { isMobile } = useMediaQuery();
  // const [isLoading, setIsLoading] = useState(false);
  // const [logs, setLogs] = useState([]);

  // const getLogs = async () => {
  //   setIsLoading(true);
  //   try {
  //     const res = await axios.get(
  //       `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_COMBINED_LOGS_SHEET_ID}&resType=raw`,
  //       {
  //         mode: 'no-cors',
  //       },
  //     );
  //     setLogs(res.data.data);
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   const intervalCall = setInterval(() => {
  //     getLogs();
  //   }, SYSTEM_REFRESH_RATE);
  //   return () => {
  //     clearInterval(intervalCall);
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   getLogs();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [logs]);

  // const data = logs.map((log) => ({
  //   id: +1,
  //   time_added: moment(log.Date_Time).format('hh:mm:ss A'),
  //   ...log,
  // }));

  const navigate = useNavigate();

  const actions = [
    {
      name: 'Back to Vehicles',
      icon: <FaArrowLeft />,
      handleClick: () => navigate('/project-manager'),
      disabled: false,
    },
  ];

  return (
    <>
      <NavHeader isMobile={isMobile} pageTitle='Site Manager Portal' />

      <div className='container-fluid text-primary'>
        <div className='row g-0 align-items-center justify-content-center mb-5'>
          <div className='col-12 col-sm-10 col-md-8'>
            <div className='row g-0 mb-3'>
              <div className='col-12'>
                <div className='row max-800 px-2 pt-4 pb-4'>
                  <LogsComponent actions={actions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logs;
