import React, { createElement } from 'react';

import Search from '../Search';

/**
 * @typedef Action
 * @property {string} name
 * @property {JSX.Element} icon
 * @property {Function} handleClick
 */

/**
 *
 * @typedef Search
 * @property {Function} onInputChange
 * @property {Function} onFieldChange
 * @property {string[]} fields
 */

/**
 * @name PageHeader
 * @param {{title: string, actions: Action[], search: Search }} props
 */
const PageHeader = ({ title, actions, search }) => (
  <div className='row g-0 align-items-center justify-content-center my-2'>
    <div className='col-12'>
      <div className='row g-0 mb-1 align-items-center justify-content-between'>
        <div className='col-12 col-md-6 text-primary'>
          <h3 style={{ fontFamily: 'Strawford Bold' }}>{title}</h3>
        </div>

        {actions && (
          <div className='col d-flex align-item-center justify-content-end'>
            <div className='btn-group'>
              {actions.map((action, index) => {
                const { name, icon, handleClick, ...props } = action || {};

                return (
                  <button
                    key={index}
                    type='button'
                    className='btn btn-outline-primary btn-sm'
                    onClick={handleClick}
                    {...props}
                  >
                    <div className='col d-flex align-items-center justify-content-between'>
                      {icon}
                      {createElement('span', { className: 'ms-1' }, name)}
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>

      {search && (
        <div className='mb-2'>
          <Search {...search} />
        </div>
      )}
    </div>
  </div>
);

export default PageHeader;
