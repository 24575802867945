import React from 'react';
import { Loader } from 'Commons';
import { useMediaQuery } from 'Hooks';
import VehicleCard from './VehicleCard';

const MobileView = ({ vehicles, isLoading }) => {
  const { isMobile } = useMediaQuery();

  const isEmpty = !isLoading && vehicles?.length < 1;

  return (
    <div className='row g-0 align-items-center justify-content-center mobile'>
      <div
        className='col-12'
        style={{
          marginBottom: isMobile ? '120px' : '80px',
        }}
      >
        {isLoading && vehicles.length < 1 ? (
          <Loader minor_msg='Checking for available trucks...' />
        ) : (
          vehicles.map((vehicle, index) => <VehicleCard key={index} vehicle={vehicle} />)
        )}
        {isEmpty && (
          <div className='d-flex align-items-center justify-content-center no-results-wrapper'>
            No Vehicles available yet
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileView;
