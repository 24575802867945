import { Registration } from 'Components/Authentication';
import NotFound from 'Components/404';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import ProjectManager from 'Components/Logsheets/ProjectManager';
import SiteManager from 'Components/Logsheets/SiteManager';
import { useCookies } from 'react-cookie';
import { ROLES } from 'Constants';
import { useDispatch } from 'react-redux';
import { logout } from 'Store/slices/authSlice';
import Admin from 'Components/Logsheets/Admin';
import Logs from 'Components/Logsheets/ProjectManager/Logs';
import AdminStatistics from 'Components/Logsheets/Admin/AdminStatistics';

const AppRoutes = () => {
  const [cookies, , removeCookie] = useCookies(['user']);
  const isLoggedIn = !!cookies.user;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRole = cookies?.user?.Role?.toLowerCase();

  useEffect(() => {
    if (
      cookies?.user &&
      (userRole === ROLES.PROJECT_MANAGER.role || userRole === ROLES.SITE_MANAGER.role)
    ) {
      if (
        new Date(cookies?.user?.Date_Time).toLocaleDateString() !== new Date().toLocaleDateString()
      ) {
        removeCookie('user', { path: '/' });
        dispatch(logout());
        navigate('/');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectUrl = `${userRole}-manager`;

  return (
    <Routes>
      <Route
        path='/'
        element={
          isLoggedIn ? (
            <Navigate to={userRole === 'admin' ? '/admin' : `${redirectUrl}`} />
          ) : (
            <Registration />
          )
        }
      />
      <Route
        path='/site-manager'
        element={
          !isLoggedIn || userRole !== ROLES.SITE_MANAGER.role ? (
            <Navigate to='/' />
          ) : (
            <SiteManager />
          )
        }
      />
      <Route
        path='/project-manager'
        element={
          !isLoggedIn || userRole !== ROLES.PROJECT_MANAGER.role ? (
            <Navigate to='/' />
          ) : (
            <ProjectManager />
          )
        }
      />
      <Route
        path='/project-manager/logs'
        element={
          !isLoggedIn || userRole !== ROLES.PROJECT_MANAGER.role ? <Navigate to='/' /> : <Logs />
        }
      />
      <Route
        path='/admin'
        element={!isLoggedIn || userRole !== ROLES.ADMIN.role ? <Navigate to='/' /> : <Admin />}
      />

      <Route
        path='/admin/statistics'
        element={
          !isLoggedIn || userRole !== ROLES.ADMIN.role ? <Navigate to='/' /> : <AdminStatistics />
        }
      />

      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
