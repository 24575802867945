import axios from 'axios';
import TableComponent from 'Commons/Table';
import { logsColumns } from 'Commons/Table/Columns';
import { NavHeader } from 'Components/Navigation';
import { PageHeader } from 'Commons';
import {
  SYSTEM_REFRESH_RATE,
  LOGSHEETS_BORROWPIT_LOGS_SHEET_ID,
  LOGSHEETS_DUMPSITE_LOGS_SHEET_ID,
  LOGSHEETS_LOGS_SHEET_BASE_URL,
} from 'Constants';
import { useMediaQuery } from 'Hooks';
import moment from 'moment';
import { MdOutlineHideSource } from 'react-icons/md';
import { BiShow } from 'react-icons/bi';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import VehicleActions from './VehicleActions';
import RealTimeStatistics from '../Admin/RealTimeStatistics';

const SiteManager = () => {
  const user = useSelector((state) => state.auth.user);
  const { isMobile } = useMediaQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  const userPosition = user?.Position?.toLowerCase()?.replace(/ /g, '');
  const getLogs = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${
          userPosition === 'dumpsite'
            ? LOGSHEETS_DUMPSITE_LOGS_SHEET_ID
            : LOGSHEETS_BORROWPIT_LOGS_SHEET_ID
        }&resType=raw`,
        {
          mode: 'no-cors',
        },
      );
      setLogs(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const intervalCall = setInterval(() => {
      getLogs();
    }, SYSTEM_REFRESH_RATE);
    return () => {
      clearInterval(intervalCall);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = logs.map((log) => ({
    id: +1,
    time_added: moment(log.Date_Time).format('hh:mm:ss A'),
    ...log,
  }));

  const [showStatistics, setShowStatistics] = useState(false);
  const actions = [
    {
      name: showStatistics ? 'Hide Realtime Statistics' : 'Show Realtime Statistics',
      icon: showStatistics ? <MdOutlineHideSource /> : <BiShow className='fs-5' />,
      handleClick: () => setShowStatistics(!showStatistics),
      disabled: false,
    },
  ];

  return (
    <>
      <NavHeader isMobile={isMobile} pageTitle='Site Manager Portal' />

      <div className='container-fluid text-primary'>
        <div className='row g-0 align-items-center justify-content-center mb-5'>
          <div className='col-12 col-sm-10 col-md-8'>
            <div className='row g-0 mb-3'>
              <div className='col-12'>
                <div className='row max-800 p-1 booking-query'>
                  <div className='mt-4 mb-2'>
                    <PageHeader className='sticky-top' title='Add to Logsheets' actions={actions} />
                    {showStatistics && (
                      <div className='my-3'>
                        <RealTimeStatistics />
                      </div>
                    )}

                    <VehicleActions />
                  </div>
                  <div className='row g-0 align-items-center justify-content-center'>
                    <div className='col-12 table-responsive'>
                      <TableComponent
                        columns={logsColumns}
                        hideActions
                        data={
                          isLoading && logs?.length < 1
                            ? [
                                ...data,
                                {
                                  id: 1,
                                  Vehicle: 'Loading...',
                                  Action_Name: 'Loading...',
                                  Material: 'Loading...',
                                  Quantity: 'Loading...',
                                  Driver_Name: 'Loading...',
                                  time_added: 'Loading...',
                                },
                              ]
                            : data
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteManager;
