import React from 'react';
import { Field } from 'formik';
import Switch from 'react-bootstrap/esm/Switch';
import { Form } from 'react-bootstrap';

const InputType = ({
  field,
  type,
  values,
  name,
  setFieldValue,
  placeholder,
  options,
  disabled,
  rows,
  customOnChange,
  className,
  ...props
}) => {
  switch (type) {
    case 'switch':
      return (
        <div>
          <Switch
            disabled={disabled}
            checked={values[name]}
            onChange={(e) => setFieldValue(name, e)}
          />
        </div>
      );
    case 'select':
      return (
        <Form.Select
          disabled={disabled}
          value={values[name]}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            if (customOnChange) {
              customOnChange(e);
            }
          }}
        >
          {options?.map((option) => (
            <option value={option?.value} key={option?.value}>
              {option?.label}
            </option>
          ))}
        </Form.Select>
      );
    case 'textarea':
      return (
        <textarea
          disabled={disabled}
          placeholder={placeholder}
          value={values[name]}
          rows={rows}
          {...field}
          className={className}
        />
      );
    default:
      return (
        <input
          {...field}
          className={className}
          placeholder={placeholder}
          value={values[name]}
          type={type}
          disabled={disabled}
          {...props}
        />
      );
  }
};

const Input = ({
  name,
  placeholder,
  type,
  label,
  options,
  disabled,
  rows,
  className,
  customClass,
  customOnChange,
  isVolume,
  note,
  validate,
  ...props
}) => (
  <Field name={name} validate={validate}>
    {({ field, meta, form: { values, setFieldValue } }) => (
      <div className={className}>
        {label ? (
          <label htmlFor={name} className='form-label fw-bold'>
            {label}{' '}
            {isVolume && (
              <>
                (<span>M</span>
                <sup>3</sup>)
              </>
            )}
            {note && (
              <span
                className='text-muted'
                style={{
                  fontSize: '10px',
                }}
              >
                ({note})
              </span>
            )}
          </label>
        ) : null}
        <InputType
          type={type}
          field={field}
          values={values}
          name={name}
          setFieldValue={setFieldValue}
          placeholder={placeholder}
          options={options}
          disabled={disabled}
          rows={rows}
          customOnChange={customOnChange}
          {...props}
          className={`${customClass ?? ''} ${
            meta.touched && meta.error ? ' form-control is-invalid' : ' form-control'
          }`}
        />
        {meta.touched && meta.error && (
          <div
            style={{ color: '#d32f2f', fontSize: '12px', paddingLeft: 3 }}
            className='invalid-feedback'
          >
            {meta.error}
          </div>
        )}
      </div>
    )}
  </Field>
);

export default Input;
