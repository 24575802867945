/* eslint-disable arrow-body-style  */
import { useNavigate } from 'react-router-dom';
import { NavHeader } from 'Components/Navigation';
import { useMediaQuery } from 'Hooks';
import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import LogsComponent from '../ProjectManager/Logs/LogsComponent';
import RealTimeStatistics from './RealTimeStatistics';

const AdminStatistics = () => {
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();

  const actions = [
    {
      name: 'Back',
      icon: <FaArrowLeft />,
      handleClick: () => navigate('/admin'),
      disabled: false,
    },
  ];

  return (
    <>
      <NavHeader isMobile={isMobile} pageTitle='Admin Statistics' />

      <div className='container-fluid text-primary'>
        <div className='row g-0 align-items-center justify-content-center mb-5'>
          <div className='col-12 col-sm-10 col-md-8'>
            <div className='row g-0 mb-3'>
              <div className='col-12'>
                <div className='row max-800 p-1'>
                  <div className='mt-4 mb-2'>
                    <RealTimeStatistics />
                  </div>

                  <LogsComponent actions={actions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminStatistics;
