import { Alert, VehicleCard } from 'Commons';
import {
  actionTypes_BorrowPit,
  actionTypes_Dumpsite,
  LOGSHEETS_BORROWPIT_LOGS_SHEET_ID,
  LOGSHEETS_DUMPSITE_LOGS_SHEET_ID,
  LOGSHEETS_COMBINED_LOGS_SHEET_ID,
  LOGSHEETS_LOGS_SHEET_BASE_URL,
  DATE_FORMAT,
  MATERIALS,
} from 'Constants';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import updateGoogleSheetRegister from 'Utils/GoogleSheet';
import { logActionValidationSchema } from '../schema';
import VehicleActionsForm from './VehicleActionsForm';

const VehicleActions = () => {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const userPosition = user?.Position?.toLowerCase()?.replace(/ /g, '');

  const actionTypes = userPosition === 'dumpsite' ? actionTypes_Dumpsite : actionTypes_BorrowPit;

  const handleSubmission = async (values) => {
    setLoading(true);
    const actionName = actionTypes.find((action) => action.value === values.actionType).label;
    const AccessDate = moment().format(DATE_FORMAT);

    const data = [
      values.regNo.Reg_No,
      values.regNo.Material === MATERIALS.Murram.name
        ? values.regNo.Volume_Capacity
        : values.regNo.Weight_Capacity,
      values.regNo.Material === MATERIALS.Murram.name
        ? MATERIALS.Murram.unit
        : MATERIALS.Aggregate.unit,
      values.regNo.Material,
      +values.actionType,
      actionName,
      AccessDate,
      _.startCase(values.regNo.Driver_Name),
      user?.Name,
      user?.Location,
    ];

    try {
      await updateGoogleSheetRegister(
        userPosition === 'dumpsite'
          ? LOGSHEETS_DUMPSITE_LOGS_SHEET_ID
          : LOGSHEETS_BORROWPIT_LOGS_SHEET_ID,
        LOGSHEETS_LOGS_SHEET_BASE_URL,
        data,
      );
      data.splice(
        4,
        0,
        values?.regNo?.Material === MATERIALS.Murram.name &&
          values?.actionType === 2 &&
          userPosition !== 'dumpsite'
          ? values.grade
          : 'N/A',
      );
      data.push(user?.Position);
      updateGoogleSheetRegister(
        LOGSHEETS_COMBINED_LOGS_SHEET_ID,
        LOGSHEETS_LOGS_SHEET_BASE_URL,
        data,
      );

      setDisabled(true);
      Alert('success', 'Vehicle Log Submitted');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Alert('error', 'Error adding vehicle log');
    }
  };

  return (
    <div className='row g-0 align-items-center justify-content-center mb-2'>
      <div className='col-12'>
        <div className='row g-0 mb-3'>
          <div className='col-12'>
            <div className='row max-800 p-1 booking-query'>
              <div className='mt-4 mb-2'>
                <Formik
                  enableReinitialize
                  initialValues={{ regNo: '', actionType: '', grade: '' }}
                  validationSchema={logActionValidationSchema}
                  className='needs-validation'
                  onSubmit={handleSubmission}
                  onReset
                >
                  {({ errors, touched, values, handleSubmit, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className='row g-0 align-items-center justify-content-center position-relative'>
                        <>
                          <div className='col-12'>
                            <div className='d-flex justify-content-end text-primary text-capitalize fw-bold'>
                              #{userPosition}
                            </div>
                            <Row xs={12} className='g-0 align-items-center justify-content-center'>
                              <div className='card my-1'>
                                <div className='card-body'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-12 d-flex align-items-center'>
                                      <div
                                        className='text-minor'
                                        style={{
                                          width: '100%',
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '10px',
                                          paddingBottom: '5px',
                                        }}
                                      >
                                        <div className='text-nowrap fs-5'>
                                          {moment(new Date()).format('MMMM Do, YYYY, hh:mm:ss A')}
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-12'>
                                      <div
                                        className='p-4'
                                        style={{
                                          borderRadius: '5px',
                                          backgroundColor: 'aliceblue',
                                        }}
                                      >
                                        <VehicleCard vehicle={values?.regNo} />
                                      </div>
                                    </div>
                                    <VehicleActionsForm
                                      values={values}
                                      errors={errors}
                                      touched={touched}
                                      setFieldValue={setFieldValue}
                                      setDisabled={setDisabled}
                                    />
                                  </div>

                                  <div className='col-12 p-1 bg-white mt-2'>
                                    <div className='row g-0 align-items-center justify-content-end'>
                                      <div className='col-6 d-flex justify-content-end'>
                                        <Button
                                          id='submit-btn'
                                          type='submit'
                                          disabled={
                                            disabled ||
                                            loading ||
                                            !values.regNo ||
                                            !values.actionType ||
                                            (values?.regNo?.Material === MATERIALS.Murram.name &&
                                              values?.actionType === 2 &&
                                              userPosition !== 'dumpsite' &&
                                              !values.grade)
                                          }
                                        >
                                          <div className='col'>
                                            {loading ? 'Submitting...' : 'Submit'}
                                          </div>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleActions;
