import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { SelectField } from 'Commons';
import LicensePlateField from 'Commons/FormFields/LicensePlateField';
import {
  actionTypes_BorrowPit,
  actionTypes_Dumpsite,
  SYSTEM_REFRESH_RATE,
  LOGSHEETS_BORROWPIT_LOGS_SHEET_ID,
  LOGSHEETS_DUMPSITE_LOGS_SHEET_ID,
  LOGSHEETS_LOGS_SHEET_BASE_URL,
  LOGSHEETS_VEHICLES_SHEET_ID,
  MATERIALS,
} from 'Constants';
import { Field } from 'formik';
import { useSelector } from 'react-redux';

const VehicleActionsForm = ({ values = {}, touched, errors, setFieldValue, setDisabled }) => {
  const user = useSelector((state) => state.auth.user);
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);
  const [logs, setLogs] = useState([]);
  const [oppositeLogs, setOppositeLogs] = useState([]);

  const userPosition = user?.Position?.toLowerCase()?.replace(/ /g, '');

  const getManagersLogs = async () => {
    try {
      const res = await axios.get(
        `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${
          userPosition === 'dumpsite'
            ? LOGSHEETS_DUMPSITE_LOGS_SHEET_ID
            : LOGSHEETS_BORROWPIT_LOGS_SHEET_ID
        }&resType=raw`,
        {
          mode: 'no-cors',
        },
      );
      setLogs(res.data.data);
    } catch (error) {
      // setIsLoading(false);
    }
  };

  const getAllLogs = async () => {
    const res = await axios.get(
      `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${
        userPosition === 'dumpsite'
          ? LOGSHEETS_BORROWPIT_LOGS_SHEET_ID
          : LOGSHEETS_DUMPSITE_LOGS_SHEET_ID
      }&resType=raw`,
      {
        mode: 'no-cors',
      },
    );
    setOppositeLogs(res.data.data);
  };

  const getAvailableVehiclesForToday = async () => {
    setFetching(true);
    try {
      const res = await axios.get(
        `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_VEHICLES_SHEET_ID}`,
        {
          mode: 'no-cors',
        },
      );
      setData(res.data.data);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      // Alert('error', 'Failed to fetch available vehicles for today, Please enter manually');
    }
  };

  useEffect(() => {
    const intervalCall = setInterval(() => {
      getAvailableVehiclesForToday();
      getManagersLogs();
      getAllLogs();
    }, SYSTEM_REFRESH_RATE);
    return () => {
      clearInterval(intervalCall);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAvailableVehiclesForToday();
    getManagersLogs();
    getAllLogs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDisabled(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.actionType]);

  const vehicles = data.map((v) => ({ value: v, label: v.Reg_No }));

  // Define the options for the select field depending on the user position (dumpsite or borrow pit)
  const actionTypes = userPosition === 'dumpsite' ? actionTypes_Dumpsite : actionTypes_BorrowPit;

  // Filter the logs to get the last entry for the selected vehicle
  const Entries = logs?.filter((log) => log.Vehicle === values?.regNo?.Reg_No);
  const oppositeEntries = oppositeLogs?.filter((log) => log.Vehicle === values?.regNo?.Reg_No);

  // Get the last entry value
  const lastEntryValue = Entries[Entries.length - 1]?.Action_Type;

  // Get the last entries' time
  const lastEntryTime = new Date(Entries[Entries.length - 1]?.Date_Time);
  const lastOppositeEntryTime = new Date(oppositeEntries[oppositeEntries.length - 1]?.Date_Time);

  // Get the last entry value from the opposite logs
  const lastOppositeEntryValue = oppositeEntries[oppositeEntries.length - 1]?.Action_Type;

  /**
   * @name filteredOptions
   * @description Filter the options based on the last entry value
   * @param {Array} actionTypes - The options for the select field
   * @param {Array} logs - The logs for the selected vehicle
   * @param {Number} lastEntryValue - The last entry value
   * @returns {Array} - The filtered options
   */
  const filteredOptions = useMemo(() => {
    if (
      (lastEntryValue === 3 && lastOppositeEntryValue !== 3) ||
      ((!lastOppositeEntryValue || lastOppositeEntryValue !== 3) && userPosition === 'dumpsite') ||
      (lastEntryValue === 3 &&
        lastOppositeEntryValue === 3 &&
        lastOppositeEntryTime < lastEntryTime)
    ) {
      return [
        {
          label: `${values?.regNo?.Reg_No} is currently at the ${
            userPosition === 'dumpsite' ? 'Borrow Pit' : 'Dumpsite'
          }`,
          value: '',
          isDisabled: true,
        },
      ];
    }
    if (lastOppositeEntryValue === 3 && !lastEntryValue) {
      return actionTypes.map((option) => {
        if (option.value === 1) {
          return option;
        }
        return {
          ...option,
          isDisabled: true,
        };
      });
    }
    if (lastOppositeEntryValue && lastOppositeEntryValue !== 3) {
      return actionTypes.map((option) => {
        if (lastEntryValue !== 3 && option.value === 1 && userPosition !== 'dumpsite') {
          return {
            ...option,
          };
        }
        return {
          ...option,
          isDisabled: true,
        };
      });
    }
    if ((logs.length < 1 || !lastEntryValue) && userPosition !== 'dumpsite')
      return actionTypes.map((option) => {
        if (option.value !== 1) {
          return {
            ...option,
            isDisabled: true,
          };
        }
        return option;
      });
    return actionTypes.map((option) => {
      if (lastEntryValue === actionTypes[actionTypes.length - 1].value) {
        if (option.value === actionTypes[0].value) {
          return {
            ...option,
          };
        }
        return {
          ...option,
          isDisabled: true,
        };
      }
      if (option.value !== lastEntryValue + 1) {
        return {
          ...option,
          isDisabled: true,
        };
      }

      return option;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.regNo, logs, oppositeLogs]);

  useEffect(() => {
    setFieldValue('actionType', '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.regNo]);

  return (
    <>
      <div className='col-lg-6 col-sm-12  mt-2 p-1'>
        <Field
          label='License Plate'
          as={LicensePlateField}
          name='regNo'
          placeholder={
            fetching && vehicles?.length < 1 ? "Fetching today's trucks" : 'Type to select'
          }
          isSearchable
          defaultOptions={vehicles}
        />
      </div>
      <div className='col-lg-6 col-sm-12  mt-2 p-1'>
        <Field
          label='Action Type'
          as={SelectField}
          name='actionType'
          placeholder='Type to select'
          options={filteredOptions}
          touched={touched}
          errors={errors}
        />
      </div>
      {values?.regNo?.Material === MATERIALS.Murram.name &&
        values?.actionType === 2 &&
        userPosition !== 'dumpsite' && (
          <div className='col-lg-6 col-sm-12  mt-2 p-1'>
            <Field
              label='Grade'
              as={SelectField}
              name='grade'
              placeholder='Type to select'
              options={[
                { value: 'G15', label: 'G15' },
                { value: 'G30', label: 'G30' },
              ]}
              touched={touched}
              errors={errors}
            />
          </div>
        )}
    </>
  );
};

export default VehicleActionsForm;
