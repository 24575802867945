import React from 'react';
import { Card } from 'react-bootstrap';

const VehicleCard = ({ vehicle }) => {
  const { licensePlate, quantity, material, unit, driverName, time_added } = vehicle;
  const fields = [
    {
      label: 'Quantity',
      value: quantity,
    },
    {
      label: 'Material',
      value: material,
    },
    {
      label: 'Unit',
      value: unit,
    },
    {
      label: 'Driver Name',
      value: driverName,
    },
    {
      label: 'Time Added',
      value: time_added,
    },
  ];

  return (
    <Card className='mb-4 w-100'>
      <Card.Body>
        <div className='d-flex justify-content-between mb-3'>
          <div className='lead text-primary fw-bold'>{licensePlate}</div>
        </div>

        <div className='d-flex flex-column w-100'>
          {fields.map((field) => (
            <div className='d-flex justify-content-between mb-2 border-bottom' key={field.label}>
              <div className='text-primary'>{field.label}:</div>
              <div className='d-flex justify-content-start'>
                <span className='fw-light text-muted'>{field.value}</span>
              </div>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default VehicleCard;
