import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

const CreatableSelectField = (props) => {
  const { name, label, defaultOptions, createOption, onHandleChange } = props;
  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    if (defaultOptions) {
      setOptions(defaultOptions);
    }
  }, [defaultOptions]);

  const [selectedFieldValue, setSelectedFieldValue] = useState();
  const [, state, { setValue, setTouched }] = useField(name);

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    setOptions((prev) => [...prev, newOption]);
    setValue(newOption.value);
    setSelectedFieldValue(newOption);
    // onHandleCreate(newOption);
  };

  const onChange = (option) => {
    setSelectedFieldValue(option);
    setValue(option?.value);
    onHandleChange(option?.value);
  };

  return (
    <div className='col-12'>
      {label ? (
        <label htmlFor={name} className='form-label fw-bold'>
          {label}
        </label>
      ) : null}
      <CreatableSelect
        {...props}
        isClearable
        onChange={onChange}
        onCreateOption={handleCreate}
        options={options}
        name={name}
        value={selectedFieldValue}
        onBlur={setTouched}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: state.touched && state.error ? 'red' : '#ced4da',
          }),
        }}
      />
      {state.touched && state.error && (
        <div style={{ color: '#d32f2f', fontSize: '12px', paddingLeft: 3, paddingTop: 3 }}>
          {state.error}
        </div>
      )}
    </div>
  );
};

export default CreatableSelectField;
