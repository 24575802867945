import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

export default function CoverPage(props) {
  const { children } = props;

  return (
    <Container fluid className='auth-container'>
      <Row xs={12} style={{ height: '100%', paddingTop: 40 }}>
        <Col xs={12} className='img-container py-5 px-3'>
          <Image fluid src={`${process.env.PUBLIC_URL}/assets/logos/logo.png`} alt='Pearl Rides' />
          <div className='d-flex justify-content-center text-white py-3'>
            <div>
              <h2 className='fw-bold'>Pearl Rides Logsheets</h2>
            </div>
          </div>
          <div style={{ width: '100%' }}>{children}</div>
        </Col>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'white',
            fontSize: '0.8rem',
            padding: '10px 0',
            fontWeight: 'bold',
          }}
        >
          V1.0.7
        </div>
      </Row>
    </Container>
  );
}
