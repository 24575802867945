import React, { useEffect, useState } from 'react';
import { NavHeader } from 'Components/Navigation';
import _ from 'lodash';
import { Formik } from 'formik';
import axios from 'axios';
import { Alert, PageHeader } from 'Commons';
import { useMediaQuery } from 'Hooks';
import { useSelector } from 'react-redux';
import updateGoogleSheetRegister from 'Utils/GoogleSheet';
import moment from 'moment';
import { MdOutlineHideSource } from 'react-icons/md';
import { BiShow } from 'react-icons/bi';
import {
  SYSTEM_REFRESH_RATE,
  LOGSHEETS_LOGS_SHEET_BASE_URL,
  LOGSHEETS_VEHICLES_SHEET_ID,
  MATERIALS,
  DATE_FORMAT,
} from 'Constants';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { vehicleValidationSchema } from '../schema';
import Vehicles from './Vehicles';
import Vehicle from './Vehicles/Vehicle';
import RealTimeStatistics from '../Admin/RealTimeStatistics';

const initialValues = {
  driverName: '',
  driverNumber: '',
  regNo: '',
  volume: '',
  weight: '',
  material: '',
};

const ProjectManager = () => {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);

  const getAvailableVehicles = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_VEHICLES_SHEET_ID}`,
        {
          mode: 'no-cors',
        },
      );
      setData(res.data.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const intervalCall = setInterval(() => {
      getAvailableVehicles();
    }, SYSTEM_REFRESH_RATE);
    return () => {
      clearInterval(intervalCall);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAvailableVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addVehicle = async (values) => {
    setLoading(true);
    const AccessDate = moment().format(DATE_FORMAT);

    const vehicleData = [
      values.regNo?.Reg_No?.toUpperCase(),
      +values.volume,
      +values.weight,
      MATERIALS[values.material]?.name,
      _.startCase(values.driverName),
      `+256${values.driverNumber?.substring(1)}`,
      AccessDate,
      'Active',
      user?.Name,
      user?.Location,
    ];
    try {
      await updateGoogleSheetRegister(
        LOGSHEETS_VEHICLES_SHEET_ID,
        LOGSHEETS_LOGS_SHEET_BASE_URL,
        vehicleData,
      );
      Alert('success', 'Vehicle Added to list');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Alert('error', 'Error adding vehicle to list');
    }
  };

  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();

  const [showStatistics, setShowStatistics] = useState(false);
  const actions = [
    {
      name: showStatistics ? 'Hide Realtime Statistics' : 'Show Realtime Statistics',
      icon: showStatistics ? <MdOutlineHideSource /> : <BiShow className='fs-5' />,
      handleClick: () => setShowStatistics(!showStatistics),
      disabled: false,
    },
    {
      name: 'View Logs',
      icon: <AiOutlineUnorderedList />,
      handleClick: () => navigate('/project-manager/logs'),
      disabled: false,
    },
  ];

  return (
    <>
      <NavHeader isMobile={isMobile} pageTitle='Project Manager Portal' />

      <div className='container-fluid text-primary'>
        <div className='row g-0 align-items-center justify-content-center mb-5'>
          <div className='col-12 col-sm-10 col-md-8'>
            <div className='row g-0 mb-3'>
              <div className='col-12'>
                <div className='row max-800 p-1'>
                  <div className='mt-4 mb-2'>
                    <PageHeader
                      className='sticky-top'
                      title='Add Vehicle Details'
                      actions={actions}
                    />
                    {showStatistics && (
                      <div className='my-3'>
                        <RealTimeStatistics />
                      </div>
                    )}
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={vehicleValidationSchema}
                      className='needs-validation'
                      onSubmit={async (values, { resetForm }) => {
                        await addVehicle(values);
                        resetForm();
                      }}
                    >
                      {({ errors, touched, values, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                          <div className='row g-0 align-items-center justify-content-center position-relative'>
                            <>
                              <div className='col-12'>
                                <Vehicle
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  loading={loading}
                                  setFieldValue={setFieldValue}
                                  vehicles={data}
                                />
                              </div>
                            </>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <Vehicles vehicles={data} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectManager;
