/* eslint-disable arrow-body-style */
import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTable, useSortBy } from 'react-table';
import './index.css';

const TableComponent = ({
  data,
  columns,
  onViewHandler,
  onEditHandler,
  onDeleteHandler,
  hideActions,
  emptyText,
  searchTerm,
  defaultSortBy,
}) => {
  const [tableColumns, setTableColumns] = useState([]);

  useEffect(() => {
    if (!hideActions) {
      const actionColumn = {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
        style: { verticalAlign: 'middle' },
        center: true,
        Cell: ({ row }) => (
          <div className='d-flex align-items-center justify-content-center'>
            {onViewHandler && (
              <span
                title='View'
                onClick={(e) => onViewHandler(e, row.original)}
                className='pointer'
              >
                <i className='bi bi-eye mx-1 text-success' />
              </span>
            )}
            {onEditHandler && (
              <span
                title='Edit'
                onClick={(e) => onEditHandler(e, row.original)}
                className='pointer'
              >
                <i className='bi bi-pencil mx-1 text-warning' />
              </span>
            )}
            {onDeleteHandler && (
              <span
                title='Delete'
                onClick={(e) => onDeleteHandler(e, row.original)}
                className='pointer'
              >
                <i className='bi bi-trash mx-1 text-danger fs-6' />
              </span>
            )}
          </div>
        ),
      };
      setTableColumns([...columns, actionColumn]);
    } else {
      setTableColumns(columns);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const modifiedColumns = useMemo(() => tableColumns, [tableColumns]);
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: modifiedColumns,
      data,
      disableSortRemove: true,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
        sortBy: [{ id: defaultSortBy ?? 'createdAt', desc: true }],
      },
    },
    useSortBy,
  );

  return (
    <div className='mt-2'>
      <Table hover {...getTableProps()} className='table table-hover text-nowrap text-primary mt-2'>
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  scope='col'
                  className={column.canSort ? 'table-column' : ''}
                >
                  <div
                    style={{
                      gap: 4,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: column.center ? 'center' : '',
                    }}
                  >
                    {column.render('Header')}

                    {column.canSort && (
                      <div className='d-flex align-items-center justify-content-center'>
                        {column.isSortedDesc ? (
                          <i className='bi bi-caret-down-fill' />
                        ) : (
                          <i className='bi bi-caret-down' />
                        )}
                        {column.isSorted && !column.isSortedDesc ? (
                          <i className='bi bi-caret-up-fill' />
                        ) : (
                          <i className='bi bi-caret-up' />
                        )}
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={(e) => onViewHandler(e, row.original)}
                className='pointer'
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={cell.column?.className}
                    style={cell.column?.style}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {rows?.length < 1 && (
        <div className='d-flex align-items-center justify-content-center no-results-wrapper'>
          {searchTerm ? (
            <span>
              No search result for <strong>{searchTerm}</strong>
            </span>
          ) : (
            emptyText ?? 'No data'
          )}
        </div>
      )}
    </div>
  );
};

export default TableComponent;
