import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { authenticationSuccess } from 'Store/slices/authSlice';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './Routes';

const App = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(['user']);
  useEffect(() => {
    if (cookies?.user) {
      dispatch(authenticationSuccess(cookies.user));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <AppRoutes />
      <ToastContainer />
    </Router>
  );
};

export default App;
