import Dialog from 'Commons/Dialog';
import * as bootstrap from 'bootstrap';
import * as Yup from 'yup';
import { Field, Formik, useField } from 'formik';
import Alert from 'Commons/Alerts';
import React, { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { MATERIALS } from 'Constants';
import Input from './Input';
import SelectField from './SelectField';

export const validationSchema = Yup.object().shape({
  quantity: Yup.number('Quantity must be a number').required('Quantity is required'),
  material: Yup.string().required('Material is required'),
  driverName: Yup.string().required('Driver Name is required'),
});

const MODAL_ID = 'driverDetailsModal';

const LicensePlateField = (props) => {
  const [inputValue, setInputValue] = useState('');
  const { name, label, defaultOptions, noModal, onHandleChange, onHandleCreate } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    if (defaultOptions) {
      setOptions(defaultOptions);
    }
  }, [defaultOptions]);

  const [selectedFieldValue, setSelectedFieldValue] = useState();
  const [, state, { setValue, setTouched }] = useField(name);

  const closeModal = () => {
    const element = document.getElementById(MODAL_ID);
    const modal = bootstrap.Modal.getInstance(element);
    modal.hide();
  };

  const handleCreate = (values, newValue) => {
    const AccessTime = new Date().toLocaleTimeString('en-GB', { hour12: true });
    setIsLoading(true);
    const newOption = {
      label: newValue,
      value: {
        Driver_Name: values?.driverName,
        Quantity: values?.quantity,
        Unit: MATERIALS[values?.material]?.unit,
        Material: values?.material,
        Reg_No: newValue,
        Time: AccessTime.split(' ')[0],
        Time_Period: AccessTime.split(' ')[1].toUpperCase(),
      },
    };

    setIsLoading(false);
    setValue(newOption.value);
    setSelectedFieldValue(newOption);
    if (noModal) return null;
    setOptions((prev) => [...prev, newOption]);
    return closeModal();
  };

  const showModal = (newValue) => {
    if (!/^U[A-Z]{2}\s?[0-9]{3}[A-Z]$/i.test(newValue)) {
      return Alert('error', 'Invalid License Plate');
    }
    const formattedNumber = `${newValue.substring(0, 3).trim()}${' '}${newValue
      ?.substring(3)
      .trim()}`;
    setInputValue(formattedNumber.toUpperCase());

    if (noModal) {
      handleCreate({ driverName: '', volume: '' }, formattedNumber.toUpperCase());
      return null;
    }
    const element = document.getElementById(MODAL_ID);
    const modal = new bootstrap.Modal(element, { keyboard: false, backdrop: true });
    modal.show();
    return null;
  };

  const onChange = (option) => {
    setSelectedFieldValue(option);
    setValue(option?.value);
    if (onHandleChange && typeof onHandleChange === 'function') {
      onHandleChange(option?.value);
    }
  };

  const handleCreateField = (values) => {
    handleCreate(values, inputValue);
    if (onHandleCreate && typeof onHandleCreate === 'function') {
      onHandleCreate(values);
    }
  };

  return (
    <div className='col-12'>
      {label ? (
        <label htmlFor={name} className='form-label fw-bold'>
          {label}
        </label>
      ) : null}
      <CreatableSelect
        {...props}
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={onChange}
        onCreateOption={showModal}
        options={options}
        name={name}
        value={selectedFieldValue}
        onBlur={setTouched}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: state.touched && state.error ? 'red' : '#ced4da',
          }),
        }}
      />
      {state.touched && state.error && (
        <div style={{ color: '#d32f2f', fontSize: '12px', paddingLeft: 3, paddingTop: 3 }}>
          {state.error}
        </div>
      )}

      <Dialog
        id={MODAL_ID}
        type='prompt'
        title={`Vehicle Details ${inputValue}`}
        Component={<OtherDetails handleCreate={handleCreateField} />}
        modalSize='modal-lg'
      />
    </div>
  );
};

export default LicensePlateField;

const OtherDetails = ({ handleCreate }) => {
  const addDetailsHandler = (values) => {
    handleCreate(values);
  };
  return (
    <div className='row g-0 align-items-center justify-content-center'>
      <div className='col-12'>
        <Formik initialValues={{}} validationSchema={validationSchema} className='needs-validation'>
          {({ errors, touched, values }) => (
            <form>
              <div className='row g-0 align-items-center justify-content-center position-relative'>
                <>
                  <div className='col-12'>
                    <Row xs={12} className='g-0 align-items-center justify-content-center'>
                      <div className='card my-1'>
                        <div className='card-body'>
                          <div className='row g-0 align-items-center'>
                            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
                              <Field
                                label='Material'
                                as={SelectField}
                                name='material'
                                placeholder='Type to select'
                                options={[
                                  { value: 'Murram', label: 'Murram' },
                                  { value: 'Aggregate', label: 'Aggregate' },
                                ]}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
                              <Input
                                type='number'
                                name='quantity'
                                touched={touched}
                                errors={errors}
                                label={values?.material === 'Murram' ? 'Volume' : 'Weight (Tons)'}
                                isVolume={values?.material === 'Murram'}
                              />
                            </div>
                            {/* <div className='col-lg-6 col-sm-12  mt-2 p-1'>
                              <Input
                                type='number'
                                name='volume'
                                touched={touched}
                                errors={errors}
                                label='Volume'
                                // isVolume
                              />
                            </div> */}
                            <div className='col-lg-6 col-sm-12  mt-2 p-1'>
                              <Input
                                type='text'
                                name='driverName'
                                touched={touched}
                                errors={errors}
                                label='Driver Name'
                              />
                            </div>
                          </div>
                          <div className='col-12 p-1 bg-white'>
                            <div className='row g-0 align-items-center justify-content-end'>
                              <div className='col-6 d-flex justify-content-end'>
                                <Button
                                  id='submit-btn'
                                  type='button'
                                  onClick={() => addDetailsHandler(values)}
                                >
                                  <div className='col'>Proceed</div>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
