/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import * as bootstrap from 'bootstrap';
import { useMediaQuery } from 'Hooks';
import { PageHeader, Dialog } from 'Commons';
import { IoIosAddCircle } from 'react-icons/io';
import { BsFillCalendarCheckFill } from 'react-icons/bs';
import moment from 'moment';
import { MATERIALS } from 'Constants';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import VehicleActions from './VehicleActions';
import EndReport from './EndReport';

const MODAL_ID = 'vehicleModal';

const Vehicles = ({ vehicles = [], isLoading }) => {
  const [actionType, setActionType] = useState('');

  const { isMobile } = useMediaQuery();

  const showModal = () => {
    setActionType('Actions');
    const element = document.getElementById(MODAL_ID);
    const modal = new bootstrap.Modal(element, { keyboard: false, backdrop: true });
    modal.show();
  };

  const closeModal = () => {
    setActionType('');
    const element = document.getElementById(MODAL_ID);
    const modal = bootstrap.Modal.getInstance(element);
    modal.hide();
  };

  const actions = [
    {
      name: 'Truck Actions',
      icon: <IoIosAddCircle />,
      handleClick: () => showModal(),
      disabled: false,
    },
    {
      name: 'End Day',
      icon: <BsFillCalendarCheckFill />,
      handleClick: () => {
        showModal();
        setActionType('endDay');
      },
      disabled: false,
    },
  ];

  const vehiclesData = vehicles.map((v, idx) => ({
    id: +idx,
    driverName: v.Driver_Name,
    licensePlate: v.Reg_No,
    quantity: v.Material === MATERIALS.Murram.name ? v.Volume_Capacity : v.Weight_Capacity,
    material: v.Material,
    time_added: moment(v?.Date_Time).format('hh:mm:ss A'),
  }));

  return (
    <>
      <div className='container-fluid' id='vehicles'>
        <div className='row g-0 align-items-center justify-content-center'>
          <div className='col-12'>
            <PageHeader
              className='sticky-top'
              title={`Available Trucks on ${moment(new Date()).format('MMM DD, YYYY')}`}
              actions={actions}
            />
            {isMobile ? (
              <MobileView vehicles={vehiclesData} isLoading={isLoading} />
            ) : (
              <DesktopView vehicles={vehiclesData} isLoading={isLoading} />
            )}
          </div>
        </div>
      </div>
      <Dialog
        id={MODAL_ID}
        type='prompt'
        title={actionType === 'endDay' ? 'End of Day Report' : 'Vehicle Actions(During Operations)'}
        Component={
          actionType === 'endDay' ? (
            <EndReport closeModal={closeModal} />
          ) : (
            <VehicleActions closeModal={closeModal} vehicles={vehiclesData} />
          )
        }
        modalSize='modal-lg'
      />
    </>
  );
};

export default Vehicles;
