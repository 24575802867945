import { EMAIL_APIS } from 'Adapters';
import { Alert, Input } from 'Commons';
import { eodValidationSchema } from 'Components/Logsheets/schema';
import { DATE_FORMAT, LOGSHEETS_EOD_LOGS_SHEET_ID, LOGSHEETS_LOGS_SHEET_BASE_URL } from 'Constants';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { generatedEodReport } from 'Utils';
import updateGoogleSheetRegister from 'Utils/GoogleSheet';

const initialValues = {
  numberOfTrips: '',
  fuel_Diesel: '',
  fuel_Petrol: '',
  unitCost_Diesel: '',
  unitCost_Petrol: '',
};

const EndReport = ({ closeModal }) => {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  const handleSubmission = async (values) => {
    setLoading(true);
    const AccessDate = moment().format(DATE_FORMAT);

    const actionData = [
      values.numberOfTrips,
      values.fuel_Petrol ? values.fuel_Petrol : 0,
      values.unitCost_Petrol ? values.unitCost_Petrol : 0,
      values.fuel_Diesel ? values.fuel_Diesel : 0,
      values.unitCost_Diesel ? values.unitCost_Diesel : 0,
      AccessDate,
      user?.Name,
      user?.Location,
      user?.Position,
    ];
    try {
      await updateGoogleSheetRegister(
        LOGSHEETS_EOD_LOGS_SHEET_ID,
        LOGSHEETS_LOGS_SHEET_BASE_URL,
        actionData,
      );
      Alert('success', 'Reported submitted Successfully');

      const variables = await generatedEodReport('Kiwologoma-Nsasa', 'King Albert');
      const endOfDayReport = {
        to: [
          {
            name: 'Pearl Rides Admin',
            email: 'edward.katongole@pearlrides.com',
          },
        ],
        cc: [
          { name: 'Kimera Moses', email: 'kimeramoses001@gmail.com' },
          { name: 'Maurice Ntege', email: 'maurice.ntege@pearlrides.com' },
          { name: 'Maurice Ntege', email: 'maurice.ntege@pearlrides.com' },
        ],
        bcc: [],
        template: 'dashboard_report',
        subject: `Pearl Rides Kiwologoma-Nsasa Report for ${moment().format('MMMM Do, YYYY')}`,
        variables,
      };
      await EMAIL_APIS.sendEmail(endOfDayReport);
      setLoading(false);
      closeModal();
    } catch (error) {
      setLoading(false);
      Alert('error', 'Error submitting report');
    }
  };

  return (
    <div>
      <button type='button' className='btn btn-primary' aria-label='Close'>
        Test
      </button>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={eodValidationSchema}
        className='needs-validation'
        onSubmit={async (values, { resetForm }) => {
          await handleSubmission(values);
          resetForm();
        }}
      >
        {({ errors, touched, values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className='row g-0 align-items-center justify-content-center position-relative'>
              <>
                <div className='col-12'>
                  <Row xs={12} className='g-0 align-items-center justify-content-center'>
                    <div className='card my-1'>
                      <div className='card-body'>
                        <div className='row g-0 align-items-center'>
                          <div className='col-lg-6 col-sm-12  mt-2 p-1'>
                            <Input
                              type='number'
                              name='numberOfTrips'
                              touched={touched}
                              errors={errors}
                              label='Total Number of Trips'
                              min={0}
                            />
                          </div>
                          <div className='col-lg-6 col-sm-12  mt-2 p-1'>
                            <Input
                              type='number'
                              name='fuel_Diesel'
                              touched={touched}
                              errors={errors}
                              label='Total Volume of Fuel(Diesel)'
                              min={0}
                            />
                          </div>
                          <div className='col-lg-6 col-sm-12  mt-2 p-1'>
                            <Input
                              type='number'
                              name='unitCost_Diesel'
                              touched={touched}
                              errors={errors}
                              label='Unit Cost(Diesel)'
                              min={0}
                            />
                          </div>
                          <div className='col-lg-6 col-sm-12  mt-2 p-1'>
                            <Input
                              type='number'
                              name='fuel_Petrol'
                              touched={touched}
                              errors={errors}
                              label='Total Volume of Fuel(Petrol)'
                              min={0}
                            />
                          </div>
                          <div className='col-lg-6 col-sm-12  mt-2 p-1'>
                            <Input
                              type='number'
                              name='unitCost_Petrol'
                              touched={touched}
                              errors={errors}
                              label='Unit Cost(Petrol)'
                              min={0}
                            />
                          </div>
                        </div>

                        <div className='col-12 p-1 bg-white mt-2'>
                          <div className='row g-0 align-items-center justify-content-end'>
                            <div className='col-6 d-flex justify-content-end'>
                              <Button
                                id='submit-btn'
                                type='submit'
                                disabled={!values?.numberOfTrips || loading}
                              >
                                <div className='col'>{loading ? 'Saving...' : 'Submit'}</div>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EndReport;
