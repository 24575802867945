import CoverPage from 'Components/Authentication/CoverPage';
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <CoverPage>
      <Container fluid className='auth-container'>
        <h2 className='text-primary'>Ooops! Page Not Found</h2>

        <Button variant='outline-primary' className='w-auto mt-5' onClick={() => navigate('/')}>
          Back Home
        </Button>
      </Container>
    </CoverPage>
  );
};

export default NotFound;
