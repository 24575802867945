import React from 'react';

const Loader = ({ primary_msg, minor_msg }) => (
  <div className='d-flex flex-column align-items-center justify-content-center mt-3'>
    <div className='d-flex align-items-center justify-content-center'>
      <div
        className='spinner-border text-minor'
        style={{ width: '3rem', height: '3rem' }}
        role='status'
      >
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
    <div className='lead text-primary fw-bold mt-2'>{primary_msg}</div>
    <div className='text-minor fw-light'>{minor_msg}</div>
  </div>
);

export default Loader;
