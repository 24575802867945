import { MATERIALS } from 'Constants';
import moment from 'moment';
import React from 'react';

const VehicleCard = ({ vehicle }) => {
  const { Reg_No, Volume_Capacity, Weight_Capacity, Material, Unit, Driver_Name, Date_Time } =
    vehicle || {};
  return (
    <div className='text-left'>
      {!vehicle ? (
        <div className='text-center'>Select vehicle to apply action</div>
      ) : (
        <>
          <h5 className='text-left'>Selected Truck</h5>
          <div className='d-flex justify-content-start align-items-start flex-column'>
            <div>
              <strong>License Plate:</strong>
              {Reg_No}
            </div>
            <div className='text-capitalize'>
              <strong>Driver Name:</strong> {Driver_Name}
            </div>
            <div className='text-capitalize'>
              <strong>Material:</strong> {Material}
            </div>
            <div>
              {Material === MATERIALS.Murram.name ? (
                <strong>
                  Volume(M<sup>3</sup>):
                </strong>
              ) : (
                <strong>Quantity:</strong>
              )}{' '}
              {Material === MATERIALS.Murram.name ? Volume_Capacity : Weight_Capacity}{' '}
              {Material !== MATERIALS.Murram.name && Unit}
            </div>
            <div>
              <strong>Time Added:</strong> {moment(Date_Time).format('hh:mm:ss A')}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VehicleCard;
