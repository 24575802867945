/* eslint-disable arrow-body-style */
import axios from 'axios';
import { Input, SelectField } from 'Commons';
import {
  SYSTEM_REFRESH_RATE,
  vehicleOperationActionsTypes,
  LOGSHEETS_OPERATIONS_LOGS_SHEET_ID,
  LOGSHEETS_LOGS_SHEET_BASE_URL,
  LOGSHEETS_COMBINED_LOGS_SHEET_ID,
  MANAGER_POSITIONS,
  materialOptions,
} from 'Constants';
import { Field } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';

const VehicleOperationActionForm = ({ fetching, data, values, touched, errors }) => {
  const [logs, setLogs] = useState([]);
  const [vehicleLogs, setVehicleLogs] = useState([]);

  const getOperationLogs = async () => {
    const res = await axios.get(
      `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_OPERATIONS_LOGS_SHEET_ID}&resType=raw`,
      {
        mode: 'no-cors',
      },
    );
    setLogs(res.data.data);
  };

  const getVehicleLogs = async () => {
    try {
      const res = await axios.get(
        `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_COMBINED_LOGS_SHEET_ID}&resType=raw`,
        {
          mode: 'no-cors',
        },
      );
      setVehicleLogs(res.data.data);
    } catch (error) {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const intervalCall = setInterval(() => {
      getOperationLogs();
      getVehicleLogs();
    }, SYSTEM_REFRESH_RATE);
    return () => {
      clearInterval(intervalCall);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Entries = logs?.filter((log) => log.Reg_No === values?.regNo?.Reg_No);
  const vehicleEntries = vehicleLogs?.filter((log) => log.Vehicle === values?.regNo?.Reg_No);

  // Get the last entry value
  const lastEntry = vehicleEntries[vehicleEntries?.length - 1];
  const lastEntryValue = Entries[Entries.length - 1]?.Action_Type;

  const filteredOptions = useMemo(() => {
    return vehicleOperationActionsTypes.map((option) => {
      if (
        (lastEntry?.Action_Type !== 3 && lastEntry?.Position === MANAGER_POSITIONS.DUMPSITE) ||
        (lastEntry?.Action_Type !== 1 && lastEntry?.Position === MANAGER_POSITIONS.BORROWPIT)
      ) {
        if (option.value === 5) {
          return {
            ...option,
            isDisabled: true,
          };
        }
      }
      if (lastEntryValue === option.value && (option.value !== 1 || option.value !== 5)) {
        return {
          ...option,
          isDisabled: true,
        };
      }
      return option;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.regNo, logs]);

  return (
    <>
      <div className='col-lg-6 col-sm-12  mt-2 p-1'>
        <Field
          label='License Plate'
          as={SelectField}
          name='regNo'
          placeholder={fetching && data?.length < 1 ? "Fetching today's trucks" : 'Type to select'}
          options={data}
          isSearchable
        />
      </div>

      <div className='col-lg-6 col-sm-12  mt-2 p-1'>
        <Field
          label='Action Type'
          as={SelectField}
          name='actionType'
          placeholder='Type to select'
          options={filteredOptions}
          touched={touched}
          errors={errors}
        />
      </div>
      {values.actionType === 1 && (
        <>
          <div className='col-lg-6 col-sm-12  mt-2 p-1'>
            <Field
              label='Fuel Type'
              as={SelectField}
              name='fuel_Type'
              placeholder='Type to select'
              options={[
                { value: 'Diesel', label: 'Diesel' },
                { value: 'Petrol', label: 'Petrol' },
              ]}
              touched={touched}
              errors={errors}
            />
          </div>
          <div className='col-lg-6 col-sm-12  mt-2 p-1'>
            <Input
              type='number'
              name='fuel_litres'
              touched={touched}
              errors={errors}
              label='Total Volume of Fuel(Litres)'
              min={0}
            />
          </div>
          <div className='col-lg-6 col-sm-12  mt-2 p-1'>
            <Input
              type='number'
              name='unit_cost'
              touched={touched}
              errors={errors}
              label='Unit Cost(Ugx)'
              min={0}
            />
          </div>
        </>
      )}
      {values.actionType === 5 && (
        <>
          <div className='col-lg-12 col-sm-12  mt-2 p-1'>
            <Field
              label='Material'
              as={SelectField}
              name='material'
              placeholder='Type to select'
              options={materialOptions}
              touched={touched}
              errors={errors}
            />
          </div>
        </>
      )}
    </>
  );
};

export default VehicleOperationActionForm;
