import axios from 'axios';
import { Alert, VehicleCard } from 'Commons';
import { vehicleActionValidationSchema } from 'Components/Logsheets/schema';
import {
  SYSTEM_REFRESH_RATE,
  vehicleOperationActionsTypes,
  LOGSHEETS_OPERATIONS_LOGS_SHEET_ID,
  LOGSHEETS_LOGS_SHEET_BASE_URL,
  LOGSHEETS_VEHICLES_SHEET_ID,
  DATE_FORMAT,
  MATERIALS,
} from 'Constants';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import updateGoogleSheetRegister from 'Utils/GoogleSheet';
import VehicleOperationActionForm from './VehicleOperationActionForm';

const VehicleActions = ({ closeModal }) => {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);

  const [vehicles, setVehicles] = useState([]);

  const getAvailableVehicles = async () => {
    setFetching(true);
    try {
      const res = await axios.get(
        `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_VEHICLES_SHEET_ID}&status=all`,
        {
          mode: 'no-cors',
        },
      );
      setVehicles(res.data.data);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  useEffect(() => {
    const intervalCall = setInterval(() => {
      getAvailableVehicles();
    }, SYSTEM_REFRESH_RATE);
    return () => {
      clearInterval(intervalCall);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = vehicles.map((v) => ({ value: v, label: v.Reg_No }));
  const handleActionSubmission = async (values) => {
    setLoading(true);
    const actionType = vehicleOperationActionsTypes.find(
      (action) => action.value === values.actionType,
    );
    const AccessDate = moment().format(DATE_FORMAT);

    const actionData = [
      values.regNo.Reg_No,
      values.regNo.Material === MATERIALS.Murram.name
        ? values.regNo.Volume_Capacity
        : values.regNo.Weight_Capacity,
      values.regNo.Material === MATERIALS.Murram.name
        ? MATERIALS.Murram.unit
        : MATERIALS.Aggregate.unit,
      values.regNo.Material,
      values.regNo.Driver_Name,
      AccessDate,
      actionType?.value,
      actionType.label,
      actionType?.value === 1 ? values.fuel_Type : 'N/A',
      actionType?.value === 1 ? values.fuel_litres : 'N/A',
      actionType?.value === 1 ? values.unit_cost : 'N/A',
      user?.Name,
      user?.Location,
      user?.Position,
    ];

    try {
      await updateGoogleSheetRegister(
        LOGSHEETS_OPERATIONS_LOGS_SHEET_ID,
        LOGSHEETS_LOGS_SHEET_BASE_URL,
        actionData,
      );
      if (values.actionType === 2 || values.actionType === 3) {
        const vehicleData = [
          values.regNo.Reg_No?.toUpperCase(),
          values.regNo.Volume_Capacity,
          values.regNo.Weight_Capacity,
          values.regNo.Material,
          values.regNo.Driver_Name,
          values.regNo.Driver_Number,
          AccessDate,
          'Inactive',
          user?.Name,
          user?.Location,
        ];
        await updateGoogleSheetRegister(
          LOGSHEETS_VEHICLES_SHEET_ID,
          LOGSHEETS_LOGS_SHEET_BASE_URL,
          vehicleData,
        );
      } else if (values.actionType === 4) {
        const vehicleData = [
          values.regNo.Reg_No?.toUpperCase(),
          values.regNo.Volume_Capacity,
          values.regNo.Weight_Capacity,
          values.regNo.Material,
          values.regNo.Driver_Name,
          values.regNo.Driver_Number,
          AccessDate,
          'Active',
          user?.Name,
          user?.Location,
        ];
        await updateGoogleSheetRegister(
          LOGSHEETS_VEHICLES_SHEET_ID,
          LOGSHEETS_LOGS_SHEET_BASE_URL,
          vehicleData,
        );
      } else if (values.actionType === 5) {
        const vehicleData = [
          values.regNo.Reg_No?.toUpperCase(),
          values.regNo.Volume_Capacity,
          values.regNo.Weight_Capacity,
          values.material,
          values.regNo.Driver_Name,
          values.regNo.Driver_Number,
          AccessDate,
          'Active',
          user?.Name,
          user?.Location,
        ];
        await updateGoogleSheetRegister(
          LOGSHEETS_VEHICLES_SHEET_ID,
          LOGSHEETS_LOGS_SHEET_BASE_URL,
          vehicleData,
        );
      }
      Alert('success', 'Action Reported Successfully');
      setLoading(false);
      closeModal();
    } catch (error) {
      setLoading(false);
      Alert('error', 'Error reporting action');
    }
  };

  return (
    <div className='row g-0 align-items-center justify-content-center mb-2'>
      <div className='col-12'>
        <div className='row g-0 mb-3'>
          <div className='col-12'>
            <div className='row max-800 p-1 booking-query'>
              <div className='mt-4 mb-2'>
                <Formik
                  enableReinitialize
                  initialValues={{ regNo: '', actionType: '' }}
                  validationSchema={vehicleActionValidationSchema}
                  className='needs-validation'
                  onSubmit={async (values, { resetForm }) => {
                    await handleActionSubmission(values);
                    resetForm();
                  }}
                >
                  {({ errors, touched, values, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <div className='row g-0 align-items-center justify-content-center position-relative'>
                        <>
                          <div className='col-12'>
                            <Row xs={12} className='g-0 align-items-center justify-content-center'>
                              <div className='card my-1'>
                                <div className='card-body'>
                                  <div className='row g-0 align-items-center'>
                                    <div className='col-12'>
                                      <div
                                        className='p-4 text-center'
                                        style={{
                                          borderRadius: '5px',
                                          backgroundColor: 'aliceblue',
                                        }}
                                      >
                                        <VehicleCard vehicle={values?.regNo} />
                                      </div>
                                    </div>
                                    <VehicleOperationActionForm
                                      data={data}
                                      fetching={fetching}
                                      values={values}
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>

                                  <div className='col-12 p-1 bg-white mt-2'>
                                    <div className='row g-0 align-items-center justify-content-end'>
                                      <div className='col-6 d-flex justify-content-end'>
                                        <Button id='submit-btn' type='submit'>
                                          <div className='col' disabled={loading}>
                                            {loading ? 'Reporting...' : 'Report'}
                                          </div>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleActions;
