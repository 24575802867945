import { toast } from 'react-toastify';

/**
 * @name Alert
 * @param {"success" | "error" | "info" | "warning"} variant
 * @param {string} message
 * @param {Object} options
 */
const Alert = (variant, message, options) => toast[variant](message, options);

/**
 * @name AlertUpdate
 * @param {string} id
 * @param {Object} options
 */
export const AlertUpdate = (id, options) => toast.update(id, options);

export default Alert;
