import * as Yup from 'yup';
import { AiOutlineSend } from 'react-icons/ai';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import React from 'react';
import { Input } from 'Commons';

const vehicleActionValidationSchema = Yup.object().shape({
  code: Yup.number()
    .required('Auth code is Required')
    .test('len', 'Must be exactly 6 characters', (val) => val?.toString().length === 6),
});

const AuthForm = ({ loading, handleActionSubmission }) => (
  <div className='auth-container'>
    <h1 className='text-primary'>Access Code</h1>
    <p className='mb-0 text-primary'>Enter a 6-digit Code from Admin</p>
    <div className='mt-1 mb-2'>
      <Formik
        initialValues={{ code: '' }}
        validationSchema={vehicleActionValidationSchema}
        className='needs-validation'
        onSubmit={handleActionSubmission}
      >
        {({ errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className='row g-0 align-items-center justify-content-center position-relative'>
              <>
                <div className='col-12'>
                  <Row xs={12} className='g-0 align-items-center justify-content-center'>
                    <div className='col-lg-10 col-sm-10 mt-2 p-1'>
                      <Input
                        type='number'
                        name='code'
                        touched={touched}
                        errors={errors}
                        autoComplete='off'
                        disabled={loading}
                        min={0}
                      />
                    </div>
                  </Row>
                </div>
                <Col xs={12} className='btn-container mt-3'>
                  <Button
                    variant={loading ? 'primary' : 'outline-primary'}
                    type='submit'
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner animation='border' size='sm' role='status' variant='minor' />
                    ) : (
                      <div className='d-flex align-items-center gap-3'>
                        Login
                        <AiOutlineSend />
                      </div>
                    )}
                  </Button>
                </Col>
              </>
            </div>
          </form>
        )}
      </Formik>
    </div>
  </div>
);

export default AuthForm;
