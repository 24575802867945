import React, { useEffect, useState } from 'react';
import { PageHeader } from 'Commons';
import TableComponent from 'Commons/Table';
import { logsColumns } from 'Commons/Table/Columns';
import moment from 'moment';
import {
  LOGSHEETS_COMBINED_LOGS_SHEET_ID,
  LOGSHEETS_LOGS_SHEET_BASE_URL,
  SYSTEM_REFRESH_RATE,
} from 'Constants';
import axios from 'axios';

const LogsComponent = ({ actions }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  const getLogs = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${LOGSHEETS_LOGS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_COMBINED_LOGS_SHEET_ID}&resType=raw`,
        {
          mode: 'no-cors',
        },
      );
      setLogs(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const intervalCall = setInterval(() => {
      getLogs();
    }, SYSTEM_REFRESH_RATE);
    return () => {
      clearInterval(intervalCall);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs]);

  const data = logs.map((log) => ({
    id: +1,
    time_added: moment(log.Date_Time).format('hh:mm:ss A'),
    ...log,
  }));

  return (
    <>
      <PageHeader
        className='sticky-top'
        title={`Project Logs on ${moment(new Date()).format('MMM DD, YYYY')}`}
        actions={actions}
      />
      <div className='row g-0 align-items-center justify-content-center'>
        <div className='col-12 table-responsive'>
          <TableComponent
            columns={logsColumns}
            hideActions
            data={
              isLoading && data?.length < 1
                ? [
                    ...data,
                    {
                      id: 1,
                      Vehicle: 'Loading...',
                      Action_Name: 'Loading...',
                      Quantity: 'Loading...',
                      Unit: 'Loading...',
                      Material: 'Loading...',
                      Driver_Name: 'Loading...',
                      time_added: 'Loading...',
                    },
                  ]
                : data
            }
          />
        </div>
      </div>
    </>
  );
};

export default LogsComponent;
