/*
 * @name: updateGoogleSheetRegister
 * @description: Function to update Google Sheet with new data data
 * @param {string} sheetId - Google Sheet ID
 * @param {string} sheetUrl - Google Sheet URL
 * @param {object} rowData - Data to be uploaded to Google Sheet
 * @returns {object} - Response from Google Sheet
 */
const updateGoogleSheetRegister = async (sheetId, sheetUrl, rowData) => {
  if (!sheetId && !sheetUrl) return { error: 'No sheetId or sheetUrl provided' };
  const data = {
    sheetId: +sheetId,
    data: rowData,
  };

  try {
    const res = await fetch(sheetUrl, {
      mode: 'no-cors',
      method: 'POST',
      headers: new Headers({
        'Content-type': 'application/json',
      }),
      body: JSON.stringify(data),
    });
    return res;
  } catch (e) {
    return e;
  }
};

export default updateGoogleSheetRegister;
