import { FILE_SERVER_URL } from 'Constants';
import axios from 'axios';

const baseUrl = FILE_SERVER_URL;

/**
 * Handle all the API calls to handle send email notifications
 * @name emailAdapter
 * @param {(config: RequestConfig)=> any} requestor
 */
const emailAdapter = () => ({
  sendEmail: async (data) => {
    const url = `${baseUrl}/email`;
    const response = await axios.post(url, data);
    return response;
  },
});

export default emailAdapter;
