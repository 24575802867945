import { Alert } from 'Commons';
import { useCookies } from 'react-cookie';
import React, { useState } from 'react';
import { authenticationSuccess } from 'Store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  LOGSHEETS_SITE_MANAGERS_SHEET_ID,
  LOGSHEETS_MANAGERS_SHEET_BASE_URL,
  ROLES,
} from 'Constants';
import axios from 'axios';
import CoverPage from './CoverPage';
import AuthForm from './AuthForm';

const Registration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setCookie] = useCookies(['user']);
  const [loading, setLoading] = useState(false);

  const getAvailableManagers = async (values) => {
    const { code } = values;
    if (code) {
      setLoading(true);
      try {
        const res = await axios.get(
          `${LOGSHEETS_MANAGERS_SHEET_BASE_URL}?sheetId=${LOGSHEETS_SITE_MANAGERS_SHEET_ID}&code=${code}`,
          {
            mode: 'no-cors',
          },
        );
        const { data } = res.data;
        if (data?.status !== 'fail') {
          const maxAge = 12 * 60 * 60; // auth code and user object in cookies deleted after 12 hours
          setCookie('user', data, { path: '/', maxAge });
          dispatch(authenticationSuccess(data));

          // redirect to appropriate dashboard depending on role of user logged in  (project manager, site manager)
          if (data.Role.toLowerCase() === ROLES.PROJECT_MANAGER.role.toLowerCase()) {
            navigate('/project-manager');
          } else if (data.Role.toLowerCase() === ROLES.SITE_MANAGER.role.toLowerCase()) {
            navigate('/site-manager');
          } else if (data.Role.toLowerCase() === ROLES.ADMIN.role.toLowerCase()) {
            navigate('/admin');
          }
        } else {
          Alert('error', 'Invalid or Expired code');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Alert('error', 'Error authenticating code');
      }
    } else {
      Alert('error', 'Please enter a valid code');
    }
  };

  return (
    <CoverPage>
      <Col xs={12} className='btn-container'>
        <AuthForm handleActionSubmission={getAvailableManagers} loading={loading} />
      </Col>
    </CoverPage>
  );
};

export default Registration;
